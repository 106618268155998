<template >
    <div>
           <section id="pricing-plan">
                    <!-- title text and switch button -->
                    <div class="text-center">
                        <h1 class="mt-5">Phoenixgn Packages</h1>
                       
                        
                    </div>
                    <!--/ title text and switch button -->

                    <!-- pricing plan cards -->
                    <div class="row pricing-card">
                        <div class="col-12 col-sm-offset-2 col-sm-10 col-md-12 col-lg-offset-2 col-lg-10 mx-auto">
                            <div class="row">
                                <!-- basic plan -->
                                <div v-for="phoenixgn_package in phoenixgn_packages" :key="phoenixgn_package.id" class="col-12 col-md-4" >
                                    <div class="card basic-pricing text-center">
                                        <div class="card-body">
                                            <img src="app-assets/images/illustration/Pot1.svg" class="mb-2 mt-5" alt="svg img" />
                                            <h3>{{phoenixgn_package.title}}</h3>
                                            
                                            <div class="annual-plan">
                                                <div class="plan-price mt-2">
                                                    <sup class="font-medium-1 font-weight-bold text-white">$ {{format(phoenixgn_package.reg_fee/500)}}</sup>
                                                    <span class="pricing-basic-value font-weight-bolder text-primary"></span>
                                                    
                                                </div>
                                                <small class="annual-pricing d-none text-muted"></small>
                                            </div>
                                            <ul class="list-group list-group-circle text-left">
                                                <li class="list-group-item">Direct Referral Commision: <br>
                                                    <span class="font-weight-bold badge badge-success">$ {{format(phoenixgn_package.dr_commission/ 500)}}</span></li>
                                                <li class="list-group-item">Points: <br>
                                                    <span class="font-weight-bold">{{phoenixgn_package.points}}</span> </li>
                                                <li class="list-group-item">3 in 1 Commission: <br>
                                                    <span class="font-weight-bold">{{phoenixgn_package.three_one_commission}}</span></li>
                                                <li class="list-group-item">Matching Bonus: <br>
                                                    <span class="font-weight-bold">{{phoenixgn_package.match_bonus}}</span></li>
                                                <li class="list-group-item">Daily Matching: <br>
                                                    <span class="font-weight-bold">{{phoenixgn_package.max_daily_matching}}</span></li>
                                               
                                            </ul>

                                            <div class="form-group">
                                                <input type="radio" class="form-control" name='package_id' @click="getPackageId(phoenixgn_package.id)" v-bind:value="phoenixgn_package.id" id="">
                                            </div>
                                              <paystack

                                                
                                              :class="'btn btn-block btn-outline-success mt-2' +di"
                                                :amount="phoenixgn_package.reg_fee * 100"
                                                :email="userData.email"
                                                :paystackkey="paystackkey"
                                                :reference="reference"
                                                :callback="callback"
                                                :close="close"
                                                :embed="false"
                                            >
                                                <i class="fas fa-money-bill-alt"></i>
                                                Make Payment
                                            </paystack>
                                        </div>
                                    </div>
                                </div>

                               
                                <!--/ basic plan -->

                                <!--/ enterprise plan -->
                            </div>
                        </div>
                    </div>
                    <!--/ pricing plan cards -->

                    

                  
                </section>

        
    </div>
</template>

<script>
import axios from 'axios'
// import paystack from "../../../node_modules/vue3-paystack/src/vue3-paystack.vue";


export default {
    components:{
        //  paystack,
    },
    data() {
        return {
            phoenixgn_packages: [],
                paystackkey: "pk_test_5d9f4fab1166494f3cb4fb14af308a2e2345b7c0", //paystack public key
                email: "foobar@example.com", // Customer email
                amount: 1000000, // in kobo
                userData: [],
                selected_package_id: '',
                package_id: '',
                di: 'd-none'
        }
    },

    computed: {
    reference() {
        let text = "";
        let possible =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (let i = 0; i < 10; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
        },
    },
    
    methods: {

        getPackageId(id){
            this.selected_package_id = id

            // alert(this.selected_package_id)

            // this.callback()
        },
        format(value){
            var numeral = require('numeral');

            return numeral(value).format('N 0,0.00')
        },
        callback: function () {

            // console.log()

             axios({
                url: process.env.VUE_APP_URL+'/api/payment_callback',
                method: 'post',
                data:{

                    package_id : this.selected_package_id

                },
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' +localStorage.getItem('user_token')
                },


            })
            .then((response)=>{

            console.log(response);
            return this.$router.push('/user/subscription-success')


            })
            .catch((response)=>{

                console.log(response)
            })


         
        },
        close: function () {
            console.log("Payment closed");
        },
        getPackages(){
                let loader = this.$loading.show({
                  // Optional parameters
                  container: this.fullPage ? null : this.$refs.formContainer,
                  canCancel: true,
                  color: '#7367F0',
                  loader: 'dots',
                  onCancel: this.onCancel,
                });

            axios({
                method: "get",
                url: process.env.VUE_APP_URL+'/api/packages',
            })
            .then((response)=>{
          
                this.phoenixgn_packages = response.data
                loader.hide()
                console.log(response)
            })
            .catch((response)=>{

                console.log(response)
            })
        },
        getUserData(){
            this.userData = JSON.parse(localStorage.getItem('user_data'));
        }
    },
    mounted() {
        this.getPackages()
         this.getUserData()

    },
}
</script>