<template>
<div class="">

    <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">Admin</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">Transactions</a>
                            </li>
                            <!-- <li class="breadcrumb-item"><a href="#">Pages</a>
                            </li>
                            <li class="breadcrumb-item active">FAQ
                            </li> -->
                        </ol>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
    
    <div  class="content-body">

        <div class="row">

                <div class="col-md-6 py-1">
                    <div class="card">
                        <div class="card-body text-center">
                            <h3 class="text-success">$ {{formatDigits((total_pending + company_profits)/500)}}</h3>
                            <h6 class="text-primary">Total Income</h6>
                        </div>
                    </div>
                </div>


                <div class="col-md-6 py-1">
                    <div class="card">
                        <div class="card-body text-center">
                            <h3 class="text-success">$ {{formatDigits(total_pending/500)}}</h3>
                            <h6 class="text-warning">Total Pending Payout</h6>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 py-1">
                    <div class="card">
                        <div class="card-body text-center">
                            <h3 class="text-success">$ {{formatDigits(total_paidout/500)}}</h3>
                            <h6 class="text-danger">Total Paidout</h6>
                            <router-link :to="'/withdrawals'" class="btn btn-sm btn-primary">withdrawals</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 py-1">
                    <div class="card">
                        <div class="card-body text-center">
                            <h3 class="text-success">$ {{formatDigits(company_profits/500)}}</h3>
                            <h6 class="text-success">Company Profit</h6>
                        </div>
                    </div>
                </div>


        </div>

        <div class="container table-responsive">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Account</th>
                        <th>Description</th>
                        <th>Credit</th>
                        <th>Debit</th>
                        <th>Date</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="transaction in transactions" :key="transaction.index">
                        <td>#</td>
                        <td>{{transaction.user.email}} <br>{{transaction.user.usercode}}</td>
                        <td>
                            <span class="badge badge-primary">{{transaction.status=='company'?'company':''}}</span><br>
                            {{transaction.description}}</td>
                        <td>
                           <span class="text-success font-weight-bold"> {{transaction.type == 'credit'?'$ '+formatDigits(transaction.amount/500):''}}</span>
                            
                        </td>
                        <td>
                           <span class="text-danger font-weight-bold"> {{transaction.type == 'debit'?'$ '+formatDigits(transaction.amount/500):''}}</span>
                            
                        </td>
                        <td>{{transaction.created_at}}></td>
                    </tr>
                </tbody>
            </table>

            <div class="card">
                <div class="card-body text-center">
                    <ul class="pagination url1-links">
                            <li class="page-item first disabled"><a href="" class="page-link">First</a></li>

                            <li class="page-item prev disabled"><a href="" class="page-link">Prev</a></li>

                           
                            <li v-for="link, key in links" :key="key"  class="page-item"><a :href="link.url" class="page-link">{{key + 1}}</a></li>
                           

                            <li class="page-item next"><a href="" class="page-link">Next</a></li>

                            <li class="page-item last"><a href="" class="page-link">Last</a></li>
                    </ul>
                </div>
            </div>
        </div>
        
    </div>

</div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            transactions: [],
            wallet_balance: '',
            links: [],
            total_income: '',
            total_pending: '',
            total_paidout: '',
            company_profits: ''
        }
    },

    methods: {

        
        formatDigits(digits){
     
            return new Intl.NumberFormat('en-US',{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            } ).format(digits)
        },

        getTransactions(){
            axios({
                url: process.env.VUE_APP_URL + '/api/wallet_balance',
                method: 'get',
                headers:{
                    'Authorization': 'Bearer '+localStorage.getItem('user_token')
                }
            })
            .then((response) => {

                console.log(response)

                this.transactions = response.data.user_wallet.data

                this.links = response.data.user_wallet.links

                this.wallet_balance = response.data.wallet_balance

                this.total_income = response.data.total_income
                this.total_pending = response.data.total_pending
                this.total_paidout = response.data.total_paidout
                this.company_profits = response.data.company_profits

                console.log(response)
            })
            .catch((response) => {

                console.log(response)
            })
        }


        
    },

    mounted() {

        this.getTransactions()
        
    },
}
</script>