<template>
    <div class="">

        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">Shop</h2>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="">Home</a>
                                </li>

                                <li class="breadcrumb-item active">Shop
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="content-detached ">

            <ul class="nav nav-pills nav-justified mb-3" id="pills-tab" role="tablist">
                <li class="nav-item p-3" role="presentation">
                    <button class="btn-warning nav-link active btn-block" id="pills-home-tab" data-toggle="pill" data-target="#pills-home"
                        type="button" role="tab" aria-controls="pills-home" aria-selected="true">Market Place</button>
                </li>
                <li class="nav-item p-3" role="presentation">
                    <button class="btn-success nav-link btn-block" id="pills-profile-tab" data-toggle="pill" data-target="#pills-profile"
                        type="button" role="tab" aria-controls="pills-profile" aria-selected="false">My Orders</button>
                </li>

            </ul>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    
                    <div class="content-body">

                            <section>
                                <div class="row">
                                    <div v-for="product, index in products" :key="index.id" class=" col-md-4  p-3">

                                        <div class="card ecommerce-card">
                                            <div class="item-img text-center">
                                              
                                                    <img class="img-fluid card-img-top" :src="product.featured_image"
                                                        alt="img-placeholder" />
                                            </div>
                                            <div class="card-body">
                                                <div class="item-wrapper">

                                                    <div>

                                                    </div>
                                                </div>
                                                <h6 class="item-name">
                                                    <a class="text-body" href="app-ecommerce-details.html">{{ product.name }}</a>

                                                </h6>
                                                <p class="card-text item-description">
                                                    5ml, 10ml, 20ml, 30ml, 100ml
                                                </p>
                                            </div>
                                            <div class="item-options text-center">
                                                <div class="item-wrapper">

                                                </div>

                                                <router-link :to="'/user/productorder/' + product.id"
                                                    class="btn btn-primary btn-cart btn-block">
                                                    <i data-feather="shopping-cart"></i>
                                                    <span class="add-to-cart">Place Order</span>
                                                </router-link>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </section>


                    </div>
                </div>
                <div class="tab-pane fade " id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">

                    <h6 class="py-3 "><span class="badge badge-success">My Direct Commissions</span></h6>

                    <table class="table table-responsive">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product</th>
                                <th>Purchased by</th>
                                <th>Total Commission</th>
                                <th>Total Bonus points</th>
                            </tr>
                        </thead>
                        <tbody>


                            <tr v-for="myOrder,index in my_orders" :key="myOrder.index">
                                <td>{{index +1}}</td>
                                <td>{{myOrder.product.name}}</td>

                                <td>{{myOrder.purchaser.name}}</td>
                                <td>{{format(myOrder.commission/500)}}</td>
                                <td>{{myOrder.points}}</td>

                            </tr>
                            
                        </tbody>
                    </table>

                    <h6 class="py-3"> <span class="badge badge-success"> My First Generation Commisions</span></h6>

                    <table class="table table-responsive">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product</th>
                                <th>Purchased by</th>
                                <th>Total Commission</th>
                                <th>Total Bonus points</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="firstGenOrder,index in firstGenOrders" :key="firstGenOrder.index">
                                <td>{{index +1}}</td>
                                <td>{{firstGenOrder.product.name}}</td>

                                <td>{{firstGenOrder.purchaser.name}}</td>
                                <td>{{format(firstGenOrder.commission/500)}}</td>
                                <td>{{firstGenOrder.points}}</td>

                            </tr>
                        </tbody>
                    </table>


                    <h6 class="py-3"><span class="badge badge-success">My Second Generation Commisions</span></h6>

                    <table class="table table-responsive">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product</th>
                                <th>Purchased by</th>
                                <th>Total Commission</th>
                                <th>Total Bonus points</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="secondGenOrder,index in secondGenOrders" :key="secondGenOrder.index">
                                <td>{{index +1}}</td>
                                <td>{{secondGenOrder.product.name}}</td>

                                <td>{{secondGenOrder.purchaser.name}}</td>
                                <td>{{format(secondGenOrder.commission/500)}}</td>
                                <td>{{secondGenOrder.points}}</td>

                            </tr>
                        </tbody>
                    </table>


                    <h6 class="py-3"><span class="badge badge-success"> My Third Generation Commisions</span></h6>

                    <table class="table table-responsive">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product</th>
                                <th>Purchased by</th>
                                <th>Total Commission</th>
                                <th>Total Bonus points</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="thirdGenOrder,index in thirdGenOrders" :key="thirdGenOrder.index">
                                <td>{{index + 1}}</td>
                                <td>{{thirdGenOrder.product.name}}</td>

                                <td>{{thirdGenOrder.purchaser.name}}</td>
                                <td>{{format(thirdGenOrder.commission/500)}}</td>
                                <td>{{thirdGenOrder.points}}</td>

                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </div>

    </div>


</template>


<script>
import axios from 'axios'
export default {

    data() {
        return {
            products: [],
            my_orders: [],
            firstGenOrders: [],
            secondGenOrders: [],
            thirdGenOrders: []
        }
    },

    methods: {
        format(value){
            var numeral = require('numeral');

            return numeral(value).format('$ 0,0.00')
        },
        placeOrder(id) {

            return this.$router.push('/user/productorder/' + id);
        },
        getProducts() {

            axios({
                url: process.env.VUE_APP_URL + '/api/products',
                method: 'get',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },


            })
                .then((response) => {
                    this.products = response.data
                    console.log(response)
                })
                .catch((response) => {
                    console.log(response)
                })

        },

        getMyOrders(){
            axios({
                url: process.env.VUE_APP_URL + '/api/my-orders',
                method: 'get',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },


            })
                .then((response) => {
            
                    this.my_orders = response.data.myOrders
                    this.firstGenOrders = response.data.firstGenOrders
                    this.secondGenOrders = response.data.secondGenOrders
                    this.thirdGenOrders = response.data.thirdGenOrders
                    console.log(response)
                })
                .catch((response) => {
                    console.log(response)
                })
        }
    },


    mounted() {
        this.getProducts()
        this.getMyOrders()
    },

}
</script>