<template>
<div class="">

    <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">Dashboard</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">News</a>
                            </li>
                            <!-- <li class="breadcrumb-item"><a href="#">Pages</a>
                            </li>
                            <li class="breadcrumb-item active">FAQ
                            </li> -->
                        </ol>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
    
    <div  class="content-body">

            <div class="content-detached content-lef">
                <div class="content-body">
                    <!-- Blog List -->
                    <div class="blog-list-wrapper">
                        <!-- Blog List Items -->
                        <div class="row">
                            <div class="col-md-6 col-12">
                                <div class="card">
                                 
                                     
                                        <video style="height: 240px; width: 100%;"  class="card-img-top img-fluid" width="320" height="240" controls autoplay muted>
                                            <source src="https://videos.phoenixgn.com/PHOENIX_Slide [Autosaved]tt.mp4" type="video/mp4">
                                            <source src="https://videos.phoenixgn.com/PHOENIX_Slide [Autosaved]tt.mp4" type="video/ogg">
                                            Your browser does not support the video tag.
                                        </video>
                                
                                    <div class="card-body">
                                        <h4 class="card-title">
                                            <span  class="blog-title-truncate text-body-heading">Phoenix Global Network Project Scope</span>
                                        </h4>
                                        <div class="media">
                                            <div class="avatar mr-50">
                                                <img src="app-assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" width="24" height="24" />
                                            </div>
                                            <div class="media-body">
                                                <small class="text-muted mr-25">by</small>
                                                <small><a href="javascript:void(0);" class="text-body">Fred Charles</a></small>
                                                <span class="text-muted ml-50 mr-25">|</span>
                                                <small class="text-muted">Nov. 10, 2022</small>
                                            </div>
                                        </div>
                                      
                                        <p class="card-text blog-content-truncate">
                                            Get to know the scope, the story and the journey ahead.
                                        </p>
                                        <hr />
                                        <div class="d-flex justify-content-between align-items-center d-none">
                                            <a href="page-blog-detail.html#blogComment">
                                                <div class="d-flex align-items-center d-none">
                                                    <i data-feather="message-square" class="font-medium-1 text-body mr-50"></i>
                                                    <span class="text-body font-weight-bold">8k Views</span>
                                                </div>
                                            </a>
                                            <!-- <a href="page-blog-detail.html" class="font-weight-bold">Read More</a> -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-12">
                                <div class="card">
                                 
                                     
                                        <video style="height: 240px; width: 100%;" class="card-img-top img-fluid"   controls autoplay muted>
                                            <source src="https://videos.phoenixgn.com/Phoenixgn_video with_voice.mp4" type="video/mp4">
                                            <source src="https://videos.phoenixgn.com/Phoenixgn_video with_voice.mp4" type="video/ogg">
                                            Your browser does not support the video tag.
                                        </video>
                                
                                    <div class="card-body">
                                        <h4 class="card-title">
                                            <span  class="blog-title-truncate text-body-heading">The Unique Network</span>
                                        </h4>
                                        <div class="media">
                                            <div class="avatar mr-50">
                                                <img src="app-assets/images/portrait/small/avatar-s-4.jpg" alt="Avatar" width="24" height="24" />
                                            </div>
                                            <div class="media-body">
                                                <small class="text-muted mr-25">by</small>
                                                <small><a href="javascript:void(0);" class="text-body">Edy</a></small>
                                                <span class="text-muted ml-50 mr-25">|</span>
                                                <small class="text-muted">Nov. 20, 2022</small>
                                            </div>
                                        </div>
                                      
                                        <p class="card-text blog-content-truncate">
                                            Truly the best. Building the mindset of tomorrows millionaires.
                                        </p>
                                        <hr />
                                        <div class="d-flex justify-content-between align-items-center d-none">
                                            <a href="page-blog-detail.html#blogComment">
                                                <div class="d-flex align-items-center d-none">
                                                    <i data-feather="message-square" class="font-medium-1 text-body mr-50"></i>
                                                    <span class="text-body font-weight-bold">76k Views</span>
                                                </div>
                                            </a>
                                            <!-- <a href="page-blog-detail.html" class="font-weight-bold">Read More</a> -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                            <div class="col-md-6 col-12">
                                <div class="card">
                                 
                                     
                                        <video style="height: 240px; width: 100%;" class="card-img-top img-fluid"   controls autoplay muted>
                                            <source src="https://videos.phoenixgn.com/phoenix_new.mp4" type="video/mp4">
                                            <source src="https://videos.phoenixgn.com/phoenix_new.mp4" type="video/ogg">
                                            Your browser does not support the video tag.
                                        </video>
                                
                                    <div class="card-body">
                                        <h4 class="card-title">
                                            <span to="/" class="blog-title-truncate text-body-heading">Be your own boss</span>
                                        </h4>
                                        <div class="media">
                                            <div class="avatar mr-50">
                                                <img src="app-assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" width="24" height="24" />
                                            </div>
                                            <div class="media-body">
                                                <small class="text-muted mr-25">by</small>
                                                <small><a href="javascript:void(0);" class="text-body">Edy</a></small>
                                                <span class="text-muted ml-50 mr-25">|</span>
                                                <small class="text-muted">Nov. 2, 2022</small>
                                            </div>
                                        </div>
                                      
                                        <p class="card-text blog-content-truncate">
                                            An exceptional network marketing system.
                                        </p>
                                        <hr />
                                        <div class="d-flex justify-content-between align-items-center d-none">
                                            <a href="page-blog-detail.html#blogComment">
                                                <div class="d-flex align-items-center d-none">
                                                    <i data-feather="message-square" class="font-medium-1 text-body mr-50"></i>
                                                    <span class="text-body font-weight-bold">6k Views</span>
                                                </div>
                                            </a>
                                            <!-- <a href="page-blog-detail.html" class="font-weight-bold">Read More</a> -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                        

                            
                        </div>
                        <!--/ Blog List Items -->

                        <!-- Pagination -->
                        <div class="row d-none">
                            <div class="col-12">
                                <nav aria-label="Page navigation">
                                    <ul class="pagination justify-content-center mt-2">
                                        <li class="page-item prev-item"><a class="page-link" href="javascript:void(0);"></a></li>
                                        <li class="page-item"><a class="page-link" href="javascript:void(0);">1</a></li>
                                        <li class="page-item"><a class="page-link" href="javascript:void(0);">2</a></li>
                                        <li class="page-item"><a class="page-link" href="javascript:void(0);">3</a></li>
                                        <li class="page-item active" aria-current="page"><a class="page-link" href="javascript:void(0);">4</a></li>
                                        <li class="page-item"><a class="page-link" href="javascript:void(0);">5</a></li>
                                        <li class="page-item"><a class="page-link" href="javascript:void(0);">6</a></li>
                                        <li class="page-item"><a class="page-link" href="javascript:void(0);">7</a></li>
                                        <li class="page-item next-item"><a class="page-link" href="javascript:void(0);"></a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <!--/ Pagination -->
                    </div>
                    <!--/ Blog List -->

                </div>
            </div>
            <div class="sidebar-detached sidebar-right d-none">
                <div class="sidebar">
                    <div class="blog-sidebar my-2 my-lg-0">
                        <!-- Search bar -->
                        <div class="blog-search">
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control" placeholder="Search here" />
                                <div class="input-group-append">
                                    <span class="input-group-text cursor-pointer">
                                        <i data-feather="search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!--/ Search bar -->

                        <!-- Recent Posts -->
                        <div class="blog-recent-posts mt-3">
                            <h6 class="section-label">Recent Posts</h6>
                            <div class="mt-75">
                                <div class="media mb-2">
                                    <a href="page-blog-detail.html" class="mr-2">
                                        <img class="rounded" src="app-assets/images/banner/banner-22.jpg" width="100" height="70" alt="Recent Post Pic" />
                                    </a>
                                    <div class="media-body">
                                        <h6 class="blog-recent-post-title">
                                            <a href="page-blog-detail.html" class="text-body-heading">Why Should Forget Facebook?</a>
                                        </h6>
                                        <div class="text-muted mb-0">Jan 14 2020</div>
                                    </div>
                                </div>
                                <div class="media mb-2">
                                    <a href="page-blog-detail.html" class="mr-2">
                                        <img class="rounded" src="app-assets/images/banner/banner-27.jpg" width="100" height="70" alt="Recent Post Pic" />
                                    </a>
                                    <div class="media-body">
                                        <h6 class="blog-recent-post-title">
                                            <a href="page-blog-detail.html" class="text-body-heading">Publish your passions, your way</a>
                                        </h6>
                                        <div class="text-muted mb-0">Mar 04 2020</div>
                                    </div>
                                </div>
                                <div class="media mb-2">
                                    <a href="page-blog-detail.html" class="mr-2">
                                        <img class="rounded" src="app-assets/images/banner/banner-39.jpg" width="100" height="70" alt="Recent Post Pic" />
                                    </a>
                                    <div class="media-body">
                                        <h6 class="blog-recent-post-title">
                                            <a href="page-blog-detail.html" class="text-body-heading">The Best Ways to Retain More</a>
                                        </h6>
                                        <div class="text-muted mb-0">Feb 18 2020</div>
                                    </div>
                                </div>
                                <div class="media">
                                    <a href="page-blog-detail.html" class="mr-2">
                                        <img class="rounded" src="app-assets/images/banner/banner-35.jpg" width="100" height="70" alt="Recent Post Pic" />
                                    </a>
                                    <div class="media-body">
                                        <h6 class="blog-recent-post-title">
                                            <a href="page-blog-detail.html" class="text-body-heading">Share a Shocking Fact or Statistic</a>
                                        </h6>
                                        <div class="text-muted mb-0">Oct 08 2020</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--/ Recent Posts -->

                        <!-- Categories -->
                        <div class="blog-categories mt-3">
                            <h6 class="section-label">Categories</h6>
                            <div class="mt-1">
                                <div class="d-flex justify-content-start align-items-center mb-75">
                                    <a href="javascript:void(0);" class="mr-75">
                                        <div class="avatar bg-light-primary rounded">
                                            <div class="avatar-content">
                                                <i data-feather="watch" class="avatar-icon font-medium-1"></i>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="javascript:void(0);">
                                        <div class="blog-category-title text-body">Fashion</div>
                                    </a>
                                </div>
                                <div class="d-flex justify-content-start align-items-center mb-75">
                                    <a href="javascript:void(0);" class="mr-75">
                                        <div class="avatar bg-light-success rounded">
                                            <div class="avatar-content">
                                                <i data-feather="shopping-cart" class="avatar-icon font-medium-1"></i>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="javascript:void(0);">
                                        <div class="blog-category-title text-body">Food</div>
                                    </a>
                                </div>
                                <div class="d-flex justify-content-start align-items-center mb-75">
                                    <a href="javascript:void(0);" class="mr-75">
                                        <div class="avatar bg-light-danger rounded">
                                            <div class="avatar-content">
                                                <i data-feather="command" class="avatar-icon font-medium-1"></i>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="javascript:void(0);">
                                        <div class="blog-category-title text-body">Gaming</div>
                                    </a>
                                </div>
                                <div class="d-flex justify-content-start align-items-center mb-75">
                                    <a href="javascript:void(0);" class="mr-75">
                                        <div class="avatar bg-light-info rounded">
                                            <div class="avatar-content">
                                                <i data-feather="hash" class="avatar-icon font-medium-1"></i>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="javascript:void(0);">
                                        <div class="blog-category-title text-body">Quote</div>
                                    </a>
                                </div>
                                <div class="d-flex justify-content-start align-items-center">
                                    <a href="javascript:void(0);" class="mr-75">
                                        <div class="avatar bg-light-warning rounded">
                                            <div class="avatar-content">
                                                <i data-feather="video" class="avatar-icon font-medium-1"></i>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="javascript:void(0);">
                                        <div class="blog-category-title text-body">Video</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!--/ Categories -->
                    </div>

                </div>
            </div>
        
    </div>

</div>


</template>


<script>
export default {
    
}
</script>