<template >
    <div v-bind:class="drawer_class" data-open="click" data-menu="vertical-menu-modern" data-col="">

        <!-- BEGIN: Header-->
        <nav class="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-dark navbar-shadow">

            <div class="navbar-container d-flex content">

                <div class="bookmark-wrapper d-flex align-items-center">
                    <ul class="nav navbar-nav d-xl-none">
                        <li class="nav-item">
                            <a class="nav-link menu-toggle" @click="showDrawer()" href="">
                                <i class="fa-solid fa-bars"></i>
                            </a>
                        </li>
                    </ul>

                    <ul class="nav navbar-nav">
                        <li class="nav-item d-none d-lg-block"><a class="nav-link bookmark-star"><i
                                    class="ficon text-warning" data-feather="star"></i></a>
                            <div class="bookmark-input search-input">
                                <div class="bookmark-input-icon"><i data-feather="search"></i></div>
                                <input class="form-control input" type="text" placeholder="Bookmark" tabindex="0"
                                    data-search="search">
                                <ul class="search-list search-list-bookmark"></ul>
                            </div>
                        </li>
                    </ul>
                </div>

                <ul class="nav navbar-nav align-items-center ml-auto">

                    <li class="nav-item dropdown dropdown-cart mr-25 d-none">
                        <a class="nav-link" href="javascript:void(0);" data-toggle="dropdown">
                            <i class="fa-solid fa-cart-shopping"></i>
                            <span class="badge badge-pill badge-primary badge-up cart-item-count">0</span>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right">
                            <li class="dropdown-menu-header">
                                <div class="dropdown-header d-flex">
                                    <h4 class="notification-title mb-0 mr-auto">My Cart</h4>
                                    <div class="badge badge-pill badge-light-primary">0 Items</div>
                                </div>
                            </li>
                            <li class="scrollable-container media-list">
                                <div class="d-none media align-items-center">
                                    <img class="d-block rounded mr-1" src="app-assets/images/pages/eCommerce/1.png"
                                        alt="donuts" width="62">
                                    <div class="media-body"><i class="ficon cart-item-remove" data-feather="x"></i>
                                        <div class="media-heading">
                                            <h6 class="cart-item-title">
                                                <a class="text-body" href="app-ecommerce-details.html"> Apple watch
                                                    5</a>
                                            </h6>
                                            <small class="cart-item-by">By Apple</small>
                                        </div>
                                        <div class="cart-item-qty">
                                            <div class="input-group">
                                                <input class="touchspin-cart" type="number" value="1">
                                            </div>
                                        </div>
                                        <h5 class="cart-item-price">$0.00</h5>
                                    </div>
                                </div>

                            </li>
                            <li class="dropdown-menu-footer">
                                <div class="d-flex justify-content-between mb-1">
                                    <h6 class="font-weight-bolder mb-0">Total:</h6>
                                    <h6 class="text-primary font-weight-bolder mb-0">0</h6>
                                </div><a class="btn btn-primary btn-block" href="app-ecommerce-checkout.html">Checkout</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown dropdown-notification mr-25">
                        <a class="nav-link" href="javascript:void(0);" data-toggle="dropdown">
                            <i class="fa-solid fa-bell"></i>
                            <span
                                :class="notifications.length == 0 ? 'badge badge-pill badge-danger badge-up d-none' : 'badge badge-pill badge-danger badge-up'">{{
                                    notifications.length }}</span>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right">
                            <li class="dropdown-menu-header">
                                <div class="dropdown-header d-flex">
                                    <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
                                    <div class="badge badge-pill badge-light-primary">{{ notifications.length }} New</div>
                                </div>
                            </li>
                            <li class="scrollable-container media-list">
                                <a v-for="notification in notifications" :key="notification.id" class="d-none d-flex"
                                    href="javascript:void(0)">
                                    <div class="d-none media d-flex align-items-start">
                                        <div class="media-left">
                                            <div class="d-none avatar">
                                                <img src="app-assets/images/portrait/small/avatar-s-15.jpg" alt="avatar"
                                                    width="32" height="32">
                                            </div>
                                        </div>
                                        <div class="d-non media-body">
                                            <p class="media-heading"><span class="font-weight-bolder">{{ notification.title
                                            }}</span>
                                            </p>

                                        </div>
                                    </div>
                                </a>


                            </li>
                            <li class="dropdown-menu-footer"><router-link class="btn btn-primary btn-block"
                                    :to="'/User/Notifications'">Read all notifications</router-link></li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown dropdown-user"><a class="nav-link dropdown-toggle dropdown-user-link"
                            id="dropdown-user" href="javascript:void(0);" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <div class="user-nav d-sm-flex d-none"><span class="user-name font-weight-bolder">{{
                                userData.name }}</span><span class="user-status">Member</span></div><span
                                class="avatar"><img class="round" :src="userData.avatar" alt="avatar" height="40"
                                    width="40"><span class="avatar-status-online"></span></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-user">
                            <router-link class="dropdown-item" :to="'/User/Profile'">
                                <i class="mr-50" data-feather="user"></i>
                                Profile</router-link>

                            <!-- <a class="dropdown-item" href="page-faq.html">
                                <i class="mr-50" data-feather="help-circle"></i>
                                FAQ</a> -->
                            <a class="dropdown-item" @click="logout()">
                                <i class="mr-50" data-feather="power"></i>
                                Logout
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
        <ul class="main-search-list-defaultlist d-none">
            <li class="d-flex align-items-center"><a href="javascript:void(0);">
                    <h6 class="section-label mt-75 mb-0">Files</h6>
                </a></li>
            <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between w-100"
                    href="app-file-manager.html">
                    <div class="d-flex">
                        <div class="mr-75"><img src="app-assets/images/icons/xls.png" alt="png" height="32"></div>
                        <div class="search-data">
                            <p class="search-data-title mb-0">Two new item submitted</p><small class="text-muted">Marketing
                                Manager</small>
                        </div>
                    </div><small class="search-data-size mr-50 text-muted">&apos;17kb</small>
                </a></li>
            <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between w-100"
                    href="app-file-manager.html">
                    <div class="d-flex">
                        <div class="mr-75"><img src="app-assets/images/icons/jpg.png" alt="png" height="32"></div>
                        <div class="search-data">
                            <p class="search-data-title mb-0">52 JPG file Generated</p><small class="text-muted">FontEnd
                                Developer</small>
                        </div>
                    </div><small class="search-data-size mr-50 text-muted">&apos;11kb</small>
                </a></li>
            <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between w-100"
                    href="app-file-manager.html">
                    <div class="d-flex">
                        <div class="mr-75"><img src="app-assets/images/icons/pdf.png" alt="png" height="32"></div>
                        <div class="search-data">
                            <p class="search-data-title mb-0">25 PDF File Uploaded</p><small class="text-muted">Digital
                                Marketing Manager</small>
                        </div>
                    </div><small class="search-data-size mr-50 text-muted">&apos;150kb</small>
                </a></li>
            <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between w-100"
                    href="app-file-manager.html">
                    <div class="d-flex">
                        <div class="mr-75"><img src="app-assets/images/icons/doc.png" alt="png" height="32"></div>
                        <div class="search-data">
                            <p class="search-data-title mb-0">Anna_Strong.doc</p><small class="text-muted">Web
                                Designer</small>
                        </div>
                    </div><small class="search-data-size mr-50 text-muted">&apos;256kb</small>
                </a></li>
            <li class="d-flex align-items-center"><a href="javascript:void(0);">
                    <h6 class="section-label mt-75 mb-0">Members</h6>
                </a></li>
            <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between py-50 w-100"
                    href="app-user-view.html">
                    <div class="d-flex align-items-center">
                        <div class="avatar mr-75"><img src="app-assets/images/portrait/small/avatar-s-8.jpg" alt="png"
                                height="32"></div>
                        <div class="search-data">
                            <p class="search-data-title mb-0">{{ userData.name }}</p><small class="text-muted">UI
                                designer</small>
                        </div>
                    </div>
                </a></li>
            <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between py-50 w-100"
                    href="app-user-view.html">
                    <div class="d-flex align-items-center">
                        <div class="avatar mr-75"><img src="app-assets/images/portrait/small/avatar-s-1.jpg" alt="png"
                                height="32"></div>
                        <div class="search-data">
                            <p class="search-data-title mb-0">Michal Clark</p><small class="text-muted">FontEnd
                                Developer</small>
                        </div>
                    </div>
                </a></li>
            <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between py-50 w-100"
                    href="app-user-view.html">
                    <div class="d-flex align-items-center">
                        <div class="avatar mr-75"><img src="app-assets/images/portrait/small/avatar-s-14.jpg" alt="png"
                                height="32"></div>
                        <div class="search-data">
                            <p class="search-data-title mb-0">Milena Gibson</p><small class="text-muted">Digital
                                Marketing Manager</small>
                        </div>
                    </div>
                </a></li>
            <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between py-50 w-100"
                    href="app-user-view.html">
                    <div class="d-flex align-items-center">
                        <div class="avatar mr-75"><img src="app-assets/images/portrait/small/avatar-s-6.jpg" alt="png"
                                height="32"></div>
                        <div class="search-data">
                            <p class="search-data-title mb-0">Anna Strong</p><small class="text-muted">Web
                                Designer</small>
                        </div>
                    </div>
                </a></li>
        </ul>
        <ul class="main-search-list-defaultlist-other-list d-none">
            <li class="auto-suggestion justify-content-between"><a
                    class="d-flex align-items-center justify-content-between w-100 py-50">
                    <div class="d-flex justify-content-start"><span class="mr-75"
                            data-feather="alert-circle"></span><span>No results found.</span></div>
                </a></li>
        </ul>
        <!-- END: Header-->


        <!-- BEGIN: Main Menu-->
        <div class="main-menu menu-fixed menu-dark menu-accordion menu-shadow" data-scroll-to-active="true">
            <div class="navbar-header">
                <ul class="nav navbar-nav flex-row">
                    <li class="nav-item mr-auto mt-1">
                        <a class="navbar-bran ">
                            <span class="brand-logo ">
                                <img style="min-width: 200px; height: 40px; " class="text-center"
                                    src="https://api.phoenixgn.com/phoenix_logo.png" alt="">
                            </span>

                        </a>
                    </li>

                </ul>
            </div>
            <div class="shadow-bottom"></div>

            <AdminSidebar v-if="is_admin"></AdminSidebar>
            <UserSidebar v-if="is_user"></UserSidebar>

        </div>
        <!-- END: Main Menu-->

        <!-- BEGIN: Content-->
        <div class="app-content content ">
            <div class="content-overlay"></div>
            <div class="header-navbar-shadow"></div>
            <div style="min-height: 100vh;" class="content-wrapper">
                <!-- <marquee behavior="" direction=""><h4 class="text-warning"> 🎉 Delegate Offer Start On 1st Of March 2023</h4></marquee> -->
                <router-view></router-view>
            </div>
        </div>
        <!-- END: Content-->

        <div class="" v-bind:class="sidenav_overlay" @click="collapseDrawer()"></div>
        <div class="drag-target"></div>

        <!-- BEGIN: Footer-->
        <footer class="footer footer-static footer-light">
            <p class="clearfix mb-0">
                <span class="float-md-left d-block d-md-inline-block mt-25">
                    <a class="ml-25" href="https://vicsystems.com.ng" target="_blank"></a>
                    <span class="d-none d-sm-inline-block"></span>
                </span>
                <span class="float-md-right d-none d-md-block">
                    COPYRIGHT &copy; 2022, , All rights Reserved
                </span>
            </p>
        </footer>
        <button class="btn btn-primary btn-icon scroll-top d-none" type="button"><i data-feather="arrow-up"></i></button>
        <!-- END: Footer-->
    </div>
</template>

<script>
import UserSidebar from '@/views/sidebars/UserSidebar'
import AdminSidebar from '@/views/sidebars/AdminSidebar'
import axios from 'axios'
import Pusher from 'pusher-js';

export default {

    components: {
        AdminSidebar,
        UserSidebar
    },


    data() {
        return {
            is_admin: false,
            is_user: true,
            userData: [],
            email: '',
            // drawer_menu: 'vertical-layout navbar-floating footer-static pace-done vertical-overlay-menu menu-open'

            drawer_class: 'vertical-layout vertical-menu-modern  navbar-floating footer-static',
            sidenav_overlay: 'sidenav-overlay',

            notifications: []

        }
    },

    methods: {

        getUserData() {
            this.userData = JSON.parse(localStorage.getItem('user_data'));
            this.email = this.userData.email

            this.created()

            if (localStorage.getItem('user_role') == 'admin') {

                this.is_user = false;

                this.is_admin = true;

                return this.is_admin;


            }
        },
        showDrawer() {

            this.drawer_class = 'vertical-layout navbar-floating footer-static pace-done vertical-overlay-menu menu-open'
            this.sidenav_overlay = 'show sidenav-overlay showx'
        },

        collapseDrawer() {
            this.drawer_class = 'vertical-layout vertical-menu-modern  navbar-floating footer-static'
            this.sidenav_overlay = 'sidenav-overlay'

        },

        logout() {

            axios({
                url: process.env.VUE_APP_URL + '/api/logout',
                method: 'post',
                data:{
                    userId: this.userData.id,

                },
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },


            })
                .then((response) => {

                    console.log(response)

                    alert('logging out')

                    localStorage.removeItem('user_role')
                    localStorage.removeItem('user_data')
                    localStorage.removeItem('user_token')

                    this.$router.push('/login');
                })
                .catch((response) => {
                    console.log(response)
                })



        },


        getNotifications() {

            axios({
                url: process.env.VUE_APP_URL + '/api/notifications',
                method: 'get',
                params: {
                    unread: 1
                },
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },


            })
                .then((response) => {
                    this.notifications = response.data
                    console.log(response)
                })
                .catch((response) => {
                    console.log(response)
                })

        },

        created() {

            this.pusher = new Pusher('cc9fe42ff36f622d5949', {
                cluster: 'eu'
            });

            this.channel = this.pusher.subscribe((this.email));

            console.log(this.channel)

            this.channel.bind("my-event", (data) => {
                // alert('persons')
                // Method to be dispatched on trigger.
                console.log(data)

                this.$vToastify.success({
                    'body': 'New Referral Alert',
                    'iconEnabled': true,
                    'defaultTitle': false,
                    'hideProgressbar': true
                }).setSettings({
                    canTimeout: false
                });


                // consol.log('thank God')

            });



        },

    },


    mounted() {
        this.getUserData()

        this.getNotifications()
    },

    //     beforeRouteEnter () {
    //     // ...

    //     if (localStorage.getItem('reloaded')) {
    //         // The page was just reloaded. Clear the value from local storage
    //         // so that it will reload the next time this page is visited.
    //         localStorage.removeItem('reloaded');
    //     } else {
    //         // Set a flag so that we know not to reload the page twice.
    //         localStorage.setItem('reloaded', '1');
    //         location.reload();
    //     }
    // },





}
</script>

<style >
.sidenav-overlay.showx {
    opacity: 1;
    visibility: visible;
    transition: all 0.5s ease;
}
</style>