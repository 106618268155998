<template>
<div class="">

    <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">Dashboard</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">Message</a>
                            </li>
                            <!-- <li class="breadcrumb-item"><a href="#">Pages</a>
                            </li>
                            <li class="breadcrumb-item active">FAQ
                            </li> -->
                        </ol>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
    
    <div  class="content-body">

     <div class="modal-body d-flex justify-content-center">

        <div class="c">
                 
              
            <lottie-player 
            
            src="https://assets4.lottiefiles.com/packages/lf20_htdr8jgg.json"  
            background="transparent"  
            speed="1"  
            style="min-width: 300px; height: 400px;"  
            loop  
            autoplay></lottie-player>

            <div class="e py-3 px-md-3">
                        
                <h2 class="text-center">Subscription Successful!!</h2>
          

        
            </div>

            <div class="c text-center py-1">
                 <router-link to="/user/home" class=" btn btn-warning btn-lg">My Dashboard</router-link>
  
            </div>

          </div>

         

      </div>
    </div>

</div>


</template>


<script>
export default {

    mounted() {
           if (localStorage.getItem('reloaded')) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem('reloaded');
        } else {
            // Set a flag so that we know not to reload the page twice.
            localStorage.setItem('reloaded', '1');
            location.reload();
        }
    },
    
}
</script>