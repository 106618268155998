import Vue from 'vue'
import VueRouter from 'vue-router'

// import Home from '../views/Home.vue'

import FullwidthLayout from '../views/layouts/FullwidthLayout.vue'

import DashboardLayout from '../views/layouts/DashboardLayout.vue'

import Login from '../views/auth/Login.vue'

import Register from '../views/auth/Register.vue'

import EmailVerify from '../views/auth/EmailVerify.vue'

import ForgotPassword from '../views/auth/ForgotPassword.vue'

import ResetPassword from '../views/auth/ResetPassword.vue'

import UserNotification from '../views/user/Notifications.vue'

import PhoenixgnPackages from '../views/user/PhoenixgnPackages.vue'

import SubscriptionSuccess from '../views/user/SubscriptionSuccess.vue'

import PurchaseSuccess from '../views/user/PurchaseSuccess.vue'


import UserHome from '../views/user/Home.vue'

import BreakingNews from '../views/user/BreakingNews.vue'

import FAQ from '../views/user/FAQ.vue'

import Genealogy from '../views/user/Genealogy.vue'

import NodeGenealogy from '../views/admin/NodeGenealogy.vue'

import Investments from '../views/user/Investments.vue'

import MyEarnings from '../views/user/MyEarnings.vue'

import MyProfile from '../views/user/MyProfile.vue'


import PGNStores from '../views/user/PGNStores.vue'

import ProductOrder from '../views/user/ProductOrder.vue'

import StairSteps from '../views/user/StairSteps.vue'


import AdminHome from '../views/admin/Home.vue'

import AdminAllPayouts from '../views/admin/AllPayouts.vue'

import AdminCancelledPayouts from '../views/admin/CancelledPayouts.vue'

import AdminCompletedPayouts from '../views/admin/CompletedPayouts.vue'

import AdminFAQ from '../views/admin/FAQ.vue'

import AdminGenealogy from '../views/admin/Genealogy.vue'

import AdminNodeGenealogy from '../views/admin/NodeGenealogy.vue'

import AdminMembers from '../views/admin/Members.vue'

import AdminNotifications from '../views/admin/Notifications.vue'

import AdminOrders from '../views/admin/Orders.vue'

import AdminPhoenixgnPackages from '../views/admin/PhoenixPackages.vue'

import AdminPHXNews from '../views/admin/PHXNews.vue'

import AdminPHXStores from '../views/admin/PHXStores.vue'

import AdminStairSteps from '../views/admin/StairSteps.vue'

import Transactions from '../views/admin/Transactions.vue'

import AdminEarnings from '../views/admin/MyEarnings.vue'

import Error404 from '../views/Error404.vue'




Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: '404',
    component: Error404,
      
  },
  {
    path: '/',
    name: 'UserLogin',
    component: FullwidthLayout,
      children: [
        {
          path: '',
          component: Login,
        }
      ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'UserLogin',
    component: FullwidthLayout,
      children: [
        {
          path: '',
          component: Login,
        }
      ]
  },
  {
    path: '/register',
    name: 'Register',
    component: FullwidthLayout,
      children: [
        {
          path: '',
          component: Register,
        }
      ]
  },

  {
    path: '/register/:id',
    name: 'Register',
    component: FullwidthLayout,
      children: [
        {
          path: '',
          component: Register,
        }
      ]
  },

  {
    path: '/verify',
    name: 'EmailVerify',
    component: FullwidthLayout,
      children: [
        {
          path: '',
          component: EmailVerify,
        }
      ]
  },

  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: FullwidthLayout,
      children: [
        {
          path: '',
          component: ForgotPassword,
        }
      ]
  },

  {
    path: '/reset-password/:code',
    name: 'ResetPassword',
    component: FullwidthLayout,
      children: [
        {
          path: '',
          component: ResetPassword,
        }
      ]
  },

  {
    path: '/User/',
    name: 'User',
    component: DashboardLayout,

      children: [
        {
          path: 'Home',
          component: UserHome,
        },

        {
          path: 'Notifications',
          component: UserNotification,
        },

        {
          path: 'Packages',
          component: PhoenixgnPackages,
        },

        {
          path: 'package/:id',
          component: () => import(/* webpackChunkName: "about" */ '../views/user/PhoenixgnPackage.vue')
        },

        {
          path: 'Subscription-Success',
          component: SubscriptionSuccess,
        },

        {
          path: 'Purchase-Success',
          component: PurchaseSuccess,
        },

        {
          path: 'News',
          component: BreakingNews,
        },

        {
          path: 'FAQ',
          component: FAQ,
        },

        {
          path: 'Genealogy',
          component: Genealogy,
        },

        {
          path: 'NodeGenealogy/:id',
          name: 'node-genealogy',
          component: NodeGenealogy,
        },

        {
          path: 'Investments',
          component: Investments,
        },

        {
          path: 'Earnings',
          component: MyEarnings,
        },

        {
          path: 'Profile',
          component: MyProfile,
        },

        {
          path: 'Store',
          component: PGNStores,
        },

        {
          path: 'productorder/:id',
          component: ProductOrder,
        },

        {
          path: 'StairSteps',
          component: StairSteps,
        },


      ],
      beforeEnter: (to, from, next) => {
        // ...
        if (localStorage.getItem('user_token')) {

            console.log('yes')

       next()
      
        }else{
            console.log('no board')
            router.push({
                path: '/login',
                replace: true
              });
        
  
        }
      }

  },

  {
    path: '/Admin/',
    name: 'Admin',
    component: DashboardLayout,
    // beforeEnter: () => {
    //   // ...
    //   if (localStorage.getItem('user_role') == 'admin') {
    //       console.log('yes')
         
    //   }else{
    //       console.log('no board')
    //       router.push({
    //           path: '/login',
    //           replace: true
    //         });
    //       this.$vToastify.error({
                        
    //         'body': 'Access Denied',
    //         'iconEnabled': true,
    //         'defaultTitle': false,
    //         'hideProgressbar': true
        
    //     });

    //   }
    // },
      children: [
        {
          path: 'Home',
          component: AdminHome
        },
        {
          path: '',
          component: AdminHome
        },
        {
          path: 'allpayouts',
          component: AdminAllPayouts
        },
        {
          path: 'cancelled-payouts',
          component: AdminCancelledPayouts
        },
        {
          path: 'completed-payouts',
          component: AdminCompletedPayouts
        },
        {
          path: 'faq',
          component: AdminFAQ
        },
        {
          path: 'genealogy',
          component: AdminGenealogy
        },

        {
          path: 'AdminNodeGenealogy/:id',
          name: 'admin-node-genealogy',
          component: AdminNodeGenealogy,
        },


        {
          path: 'members',
          component: AdminMembers
        },
        {
          path: 'notifications',
          component: AdminNotifications
        },
        {
          path: 'orders',
          component: AdminOrders
        },
        {
          path: 'phoenixgn-packages',
          component: AdminPhoenixgnPackages
        },
        {
          path: 'phx-news',
          component: AdminPHXNews
        },
        {
          path: 'phx-stores',
          component: AdminPHXStores
        },
        {
          path: 'stair-steps',
          component: AdminStairSteps
        },

        {
          path: 'transactions',
          component: Transactions
        },

        {
          path: 'earnings',
          component: AdminEarnings
        },


      ]
  },

  {
    path: '/',
    component: DashboardLayout,
    children:[
      {
        path: 'withdrawals',
        component: () => import(/* webpackChunkName: "about" */ '../views/WithdrawalRequests.vue')
      },
    
      {
        path: 'withdrawal/:id',
        component: () => import(/* webpackChunkName: "about" */ '../views/WithdrawalRequest.vue')
      },

      {
        path: 'leads',
        component: () => import(/* webpackChunkName: "about" */ '../views/MyLeads.vue')
      },

    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
