<template lang="">
<body class="vertical-layout vertical-menu-modern blank-page navbar-floating footer-static  " data-open="click" data-menu="vertical-menu-modern" data-col="blank-page">
    <!-- BEGIN: Content-->
    <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header row">
            </div>
            <div class="content-body">
                <div class="auth-wrapper auth-v1 px-2">
                    <div class="auth-inner py-2">
                        <!-- <marquee behavior="" direction=""><h4 class="text-warning"> 🎉 Delegate Offer Start On 1st Of March 2023.</h4></marquee> -->
                        <!-- Login v1 -->
                      <router-view></router-view>
                        <!-- /Login v1 -->
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- END: Content-->


</body>
</template>

<script>
export default {
    
}
</script>