<template>
<div class="">

    <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">Admin</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">Home</a>
                            </li>
                            <!-- <li class="breadcrumb-item"><a href="#">Pages</a>
                            </li>
                            <li class="breadcrumb-item active">FAQ
                            </li> -->
                        </ol>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
    
    <div  class="content-body">

          <section id="dashboard-ecommerce">
                    <div class="row match-height">
                        <!-- Medal Card -->
                        <div class="col-xl-5 col-md-5 col-12">
                            <div class="card card-congratulation-medal d-none">
                                <div class="card-body pt-5">
                                    <h5>Hi 🎉 Admin!</h5>
                                    <!-- <p v-if="on_package" class="card-text font-small-3">You are on {{userData.package.title}}</p>

                                    <p v-else class="card-text font-small-3">You are not on a package please purchase a package</p> -->
                                    <h3 class="mb-75 mt-2 pt-50">
                                        <a href="javascript:void(0);">$</a>
                                    </h3>
                                    
                                    <img src="app-assets/images/illustration/badge.svg" class="congratulation-medal" alt="Medal Pic" />
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-body">

                                    <div  class="text-center pt-1">

                                        <div class="container py-2">

                                            <h1 class="text-success">$ {{ formatDigit(wallet_balance/500) }}</h1>

                                            <router-link to="/admin/transactions" class="btn btn-primary text-center btn-sm">details</router-link>
                                            
                                        </div>

                                       <div class="container">
                                            <div class="row">
                                                <div class="col-6 border">


                                                    <h6 class="font-weight-bold ">$ {{formatDigit(total_income/500)}}</h6>

                                                    <h6 class="text-muted">Total Income</h6>
                                                </div>
                                                <div class="col-6 border">


                                                    <h6 class="font-weight-bold ">$ {{ formatDigit(total_payouts/500) }}</h6>

                                                    <h6 class="text-muted">Total Payouts</h6>
                                                </div>
                                        </div>
                                       </div>


                                         <div class="container pt-2">

                                            <h1 class="text-success">{{formatDigit(subscription_rate)}}%</h1>

                                            <h6>Subscription Rate</h6>
                                            
                                        </div>


                                       <div class="container py-2">
                                            <div class="row">
                                            <div class="col-6 border">
                                                <h6 class="font-weight-bold">{{total_signups}}</h6>
                                                <h6 class="text-muted">Total Signups</h6>
                                            </div>
                                            <div class="col-6 border">
                                                <h6 class="font-weight-bold">{{total_subscriptions}}</h6>
                                                <h6 class="text-muted">Total Subscriptions</h6>
                                            </div>
                                        </div>
                                       </div>

                                       

                                     
                                    </div>

                                </div>
                            </div>

                            
                        </div>
                          <div class="col-xl-7 col-md-7 col-12">
                              <div class="card ">
                                  <div   class="card-body">
                                  <line-chart  
                                  
                                    :library="legend"
                                    :data="data"
                                    :colors="['orange', 'red']"
                                    
                                        
                                    

                                    
                                    
                                    ></line-chart>
                                  </div>
                              </div>
                          </div>
                    </div>

                    <div class="row match-height">
                        <!-- Company Table Card -->
                        <div class="col-lg-6 ">
                            <div class="card card-company-table">
                                <div class="card-body ">
                                    <div class="table-responsive">
                                        <h6>Latest Subscriptions</h6>

                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Package</th>
                                                   
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="latest_subscription in latest_subscriptions" :key="latest_subscription.index">
                                                    <td>#</td>
                                                    <td>
                                                        {{latest_subscription.name}} <br>
                                                        {{latest_subscription.usercode}} <br>
                                                        

                                                        
                                                    </td>
                                                    <td>
                                                        <img style="height: 30px; min-widht: 30px;" class="shadow" :src="latest_subscription.package.featured_logo" alt=""> <br>
                                                        {{latest_subscription.package.title}}</td>
                                                  

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="card">
                                <div class="card-body">

                                  

                                    <div  class="card border-success mb-1">
                                        <div style="padding: 4px 7px;" class="container-fluid row">
                                            <div class="col-5">
                                                
                                                <img style="height: 30px; min-width: 40px;" src="packages/emerald_package.png" alt="">
                                                <h6>Emerald Package</h6>
                                            </div>
                                            <div class="col-7">
                                                <div class="pt-2"></div>
                                                <label for="">{{emeralds}}%</label>
                                                <div class="progress progress-bar-success box-shadow-6 ">
                                                
                                                    <div class="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="1" aria-valuemax="5"  :style="'width:'+emeralds+'%'" aria-describedby="example-caption-3"></div>
                                                
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>

                                     <div  class="card border-primary mb-1">
                                        <div style="padding: 4px 7px;" class="container-fluid row">
                                            <div class="col-5">
                                                
                                                <img style="height: 30px; min-width: 40px;" src="packages/sapphire_package.png" alt="">
                                                <h6>Sapphire Package</h6>
                                            </div>
                                            <div class="col-7">
                                                <div class="pt-2"></div>
                                                <label for="">{{sapphire}}%</label>
                                                <div class="progress progress-bar-primary box-shadow-6 ">
                                                    
                                                    <div class="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="1" aria-valuemax="5"  :style="'width:'+sapphire+'%'" aria-describedby="example-caption-3"></div>
                                                
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>

                                     <div  class="card border-danger mb-1">
                                        <div style="padding: 4px 7px;" class="container-fluid row">
                                            <div class="col-5">
                                                
                                                <img style="height: 30px; min-width: 40px;" src="packages/ruby_package.png" alt="">
                                                <h6>Ruby Package</h6>
                                            </div>
                                            <div class="col-7">
                                                <div class="pt-2"></div>
                                                <label for="">{{ruby}}%</label>
                                                <div class="progress progress-bar-danger box-shadow-6 ">
                                                    
                                                    <div class="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="1" aria-valuemax="5"  :style="'width:'+ruby+'%'" aria-describedby="example-caption-3"></div>
                                                
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>

                                      <div  class="card border-warning mb-1">
                                        <div style="padding: 4px 7px;" class="container-fluid row">
                                            <div class="col-5">
                                                
                                                <img style="height: 30px; min-width: 40px;" src="packages/gold_package.png" alt="">
                                                <h6>Gold Package</h6>
                                            </div>
                                            <div class="col-7">
                                                <div class="pt-2"></div>
                                                <label for="">{{gold}}%</label>
                                                <div class="progress progress-bar-warning box-shadow-6 ">
                                                    
                                                    <div class="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="1" aria-valuemax="5"  :style="'width:'+gold+'%'" aria-describedby="example-caption-3"></div>
                                                
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div  class="card border-info mb-1">
                                        <div style="padding: 4px 7px;" class="container-fluid row">
                                            <div class="col-5">
                                                
                                                <img style="height: 30px; min-width: 40px;" src="packages/diamond_package.png" alt="">
                                                <h6>Diamond Package</h6>
                                            </div>
                                            <div class="col-7">
                                                <div class="pt-2"></div>
                                                <label for="">{{diamond}}%</label>
                                                <div class="progress progress-bar-info box-shadow-6 ">
                                                    
                                                    <div class="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="1" aria-valuemax="5"  :style="'width:'+diamond+'%'" aria-describedby="example-caption-3"></div>
                                                
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div  class="card border-secondary mb-1">
                                        <div style="padding: 4px 7px;" class="container-fluid row">
                                            <div class="col-5">
                                                
                                                <img style="height: 30px; min-width: 40px;" src="packages/family_package.png" alt="">
                                                <h6>Family Package</h6>
                                            </div>
                                            <div class="col-7">
                                                <div class="pt-2"></div>
                                                <label for="">{{family}}%</label>
                                                <div class="progress progress-bar-secondary box-shadow-6 ">
                                                    
                                                    <div class="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="1" aria-valuemax="5"  :style="'width:'+family+'%'" aria-describedby="example-caption-3"></div>
                                                
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>




                                </div>
                            </div>
                        </div>
                        <!--/ Company Table Card -->

                        <!-- Developer Meetup Card -->
                        <div class="col-lg-4 col-md-6 col-12 d-none">
                            <div class="card card-developer-meetup">
                                <div class="meetup-img-wrapper rounded-top text-center">
                                    <img src="app-assets/images/illustration/email.svg" alt="Meeting Pic" height="170" />
                                </div>
                                <div class="card-body">
                                    

                                    <div class="meetup-header d-flex align-items-center">
                                        <div class="meetup-day">
                                            <h6 class="mb-0">THU</h6>
                                            <h3 class="mb-0">24</h3>
                                            <h3 class="mb-0">2022</h3>
                                        </div>
                                        <div class="my-auto">
                                            <h4 class="card-title mb-25">Phoenix News</h4>
                                            <p class="card-text mb-0">Monthly Conference</p>
                                        </div>
                                    </div>
                                    <div class="media d-none">
                                        <div class="avatar bg-light-primary rounded mr-1">
                                            <div class="avatar-content">
                                                <i data-feather="calendar" class="avatar-icon font-medium-3"></i>
                                            </div>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="mb-0">Sat, May 25, 2020</h6>
                                            <small>10:AM to 6:PM</small>
                                        </div>
                                    </div>
                                    <div class="media mt-2 d-none">
                                        <div class="avatar bg-light-primary rounded mr-1">
                                            <div class="avatar-content">
                                                <i data-feather="map-pin" class="avatar-icon font-medium-3"></i>
                                            </div>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="mb-0">Central Park</h6>
                                            <small>Manhattan, New york City</small>
                                        </div>
                                    </div>
                                    <div class="avatar-group d-none">
                                        <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Billy Hopkins" class="avatar pull-up">
                                            <img src="app-assets/images/portrait/small/avatar-s-9.jpg" alt="Avatar" width="33" height="33" />
                                        </div>
                                        <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Amy Carson" class="avatar pull-up">
                                            <img src="app-assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" width="33" height="33" />
                                        </div>
                                        <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Brandon Miles" class="avatar pull-up">
                                            <img src="app-assets/images/portrait/small/avatar-s-8.jpg" alt="Avatar" width="33" height="33" />
                                        </div>
                                         <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Billy Hopkins" class="avatar pull-up">
                                            <img src="app-assets/images/portrait/small/avatar-s-9.jpg" alt="Avatar" width="33" height="33" />
                                        </div>
                                        <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Amy Carson" class="avatar pull-up">
                                            <img src="app-assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" width="33" height="33" />
                                        </div>
                                        <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Brandon Miles" class="avatar pull-up">
                                            <img src="app-assets/images/portrait/small/avatar-s-8.jpg" alt="Avatar" width="33" height="33" />
                                        </div>
                                        <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Daisy Weber" class="avatar pull-up">
                                            <img src="app-assets/images/portrait/small/avatar-s-20.jpg" alt="Avatar" width="33" height="33" />
                                        </div>
                                        <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Jenny Looper" class="avatar pull-up">
                                            <img src="app-assets/images/portrait/small/avatar-s-20.jpg" alt="Avatar" width="33" height="33" />
                                        </div>
                                        <h6 class="align-self-center cursor-pointer ml-50 mb-0">+42</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--/ Developer Meetup Card -->

                 
                    </div>


                    <div class="container py-2 d-none">
                        <h2>All Time Reports</h2>
                    </div>

                    <div class="row match-height d-none">
                        <div class="col-md-6 col-xl-3">
                            <div class="card shadow-none bg-transparent border-primary">
                                <div class="card-body">
                                    <h2 class="card-tite">$0.00</h2>
                                    <h6 class="card-title">Total Direct Referral Commission</h6>
                                    <a class="card-text">Details</a>
                                </div>
                            </div>
                        </div>
                         <div class="col-md-6 col-xl-3">
                            <div class="card shadow-none bg-transparent border-warning">
                                <div class="card-body">
                                    <h2 class="card-tite">$0.00</h2>
                                    <h6 class="card-title">Total Indirect Referral Commission</h6>
                                    <a class="card-text">Details</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-xl-3">
                            <div class="card shadow-none bg-transparent border-success">
                                <div class="card-body">
                                    <h2 class="card-tite">$0.00</h2>
                                    <h6 class="card-title">Total 3 in 1 Commision</h6>
                                    <a class="card-text">Details</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-xl-3">
                            <div class="card shadow-none bg-transparent border-info">
                                <div class="card-body">
                                    <h2 class="card-tite">$0.00</h2>
                                    <h6 class="card-title">Total Matching Pair Bonuses</h6>
                                    
                                    <a class="card-text">Details</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-xl-3">
                            <div class="card shadow-none bg-transparent border-danger">
                                <div class="card-body">
                                    <h2 class="card-tite">$0.00</h2>
                                    <h6 class="card-title">Total Phoenix Reward</h6>
                                    <a class="card-text">Details</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-xl-3">
                            <div class="card shadow-none bg-transparent border-white">
                                <div class="card-body">
                                    <h2 class="card-tite">$0.00</h2>
                                    <h4 class="card-title">Leadership</h4>
                                    <a class="card-text">Details</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-xl-3">
                            <div class="card shadow-none bg-transparent border-dark">
                                <div class="card-body">
                                    <h2 class="card-tite">$0.00</h2>
                                    <h4 class="card-title">Product Reorder</h4>
                                    <a class="card-text">Details</a>
                                </div>
                            </div>
                        </div>

                        
                        <div class="col-md-6 col-xl-3">
                            <div class="card shadow-none bg-transparent border-success">
                                <div class="card-body">
                                    <h2 class="card-tite">$0.00</h2>
                                    <h4 class="card-title">Stair Step</h4>
                                    <a class="card-text">Details</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-xl-6">
                            <div class="card shadow-none bg-transparent border-warning">
                                <div class="card-body">
                                    <h2 class="card-tite">$0.00</h2>
                                    <h4 class="card-title">Profit Sharing</h4>
                                    <a class="card-text">Details</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-xl-6">
                            <div class="card shadow-none bg-transparent border-primary">
                                <div class="card-body">
                                    <h2 class="card-tite">$0.00</h2>
                                    <h4 class="card-title">Royalty Bonus</h4>
                                    <a class="card-text">Details</a>
                                </div>
                            </div>
                        </div>



                    </div>



                </section>

      
        
    </div>

</div>
</template>
<script>

import axios from 'axios'
export default {

    data() {
        return {
            m: 'we',
            
                    legend: {   
                        labels: {
                            fontColor: "red"
                            }
                        },
                                                        
              total_signups: '...',
                total_subscriptions: '...',
                latest_subscriptions: [],
                total_income: '...',
                total_payouts: '...',
                subscription_rate: '...',
                wallet_balance: '...',
                monthly_singups: [],
                
            data:{
                    'JAN': 0, 
                    'FEB': 0,
                    'MAR': 0, 
                    'APR': 0,
                    'MAY': 0,
                    'JUN': 0,
                    'JUL': 0,
                    'AUG': 0,
                    'SEP': 0,
                    'OCT': 0,
                    'NOV': 0,
                    'DEC': 0,
                },

            datax: '',


            emeralds: '',
            sapphire: '',
            ruby: '',
            gold: '',
            diamond: '',
            family: '',

            jade_record: []

          
        }
    },

    methods: {

        formatDigit(digits){
     
            return new Intl.NumberFormat('en-US',{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            } ).format(digits)
        },
        getDashboardData(){
            axios({
                url: process.env.VUE_APP_URL + '/api/user_stats',
                method: 'get',
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('user_token')
                }
            })
            .then((response) =>{

                            console.log(response)

                this.total_signups = response.data.total_signups
                this.total_subscriptions = response.data.total_subscriptions
                this.latest_subscriptions = response.data.latest_subscriptions
                this.total_income = response.data.total_income
                this.total_payouts = response.data.total_payouts
                this.subscription_rate = response.data.subscription_rate
                this.wallet_balance = response.data.wallet_balance
                this.monthly_singups = response.data.monthly_singups
                this.emeralds = response.data.emeralds
                this.sapphire = response.data.sapphire
                this.ruby = response.data.ruby
                this.gold = response.data.gold
                this.diamond = response.data.diamond
                this.family = response.data.family


                this.data = {
                    'JAN': this.monthly_singups[0], 
                    'FEB': this.monthly_singups[1],
                    'MAR': this.monthly_singups[2], 
                    'APR': this.monthly_singups[3],
                    'MAY': this.monthly_singups[4],
                    'JUN': this.monthly_singups[5],
                    'JUL': this.monthly_singups[6],
                    'AUG': this.monthly_singups[7],
                    'SEP': this.monthly_singups[8],
                    'OCT': this.monthly_singups[9],
                    'NOV': this.monthly_singups[10],
                    'DEC': this.monthly_singups[11],
                }


                console.log(response)

                
            })
            .catch((response) =>{
                console.log(response)
            })
        },


        checkJade(){

            axios({
                url: process.env.VUE_APP_URL+'/api/jade',
                method: 'post',
                headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' +localStorage.getItem('user_token')
                    },


            })
            .then((response)=>{

                // alert('hello');

                this.jade_record = response.data.record

                this.jade_left_count = response.data.record.left_count
                this.jade_left_count_percent = (response.data.record.left_count/5 * 100)
                this.jade_right_count_percent = (response.data.record.right_count/5 * 100)
                this.jade_right_count = response.data.record.right_count

                console.log(response)

            })
            .catch((response)=>{

                console.log(response)
            })

        },

        jadeStar(){

            // alert('hellox')

            

            axios({
                url: process.env.VUE_APP_URL+'/api/jade_star',
                method: 'post',
                headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' +localStorage.getItem('user_token')
                    },


            })
            .then((response)=>{

                // alert('hello');

                this.jade_record = response.data.record

                this.jade_left_count = response.data.record.left_count
                this.jade_left_count_percent = (response.data.record.left_count/5 * 100)
                this.jade_right_count_percent = (response.data.record.right_count/5 * 100)
                this.jade_right_count = response.data.record.right_count

                console.log(response)

            })
            .catch((response)=>{

                console.log(response)
            })

        }


    },
    mounted() {

        this.checkJade()

        this.jadeStar()


        this.getDashboardData()

        // this.renderChart(this.chartdata, this.options);
    },
 
}
</script>

<style>


canvas *{
   
   
}
    
</style>