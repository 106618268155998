<template>
    <div class="">
        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">Dashboard</h2>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/admin/genealogy">Genealogy</router-link>
                                </li>
                                <!-- <li class="breadcrumb-item"><a href="#">Pages</a>
                                </li>
                                <li class="breadcrumb-item active">FAQ
                                </li> -->
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>

        
        
            <div v-if="node00"  class="content-body  text-center py-2">
                <div class="tf-tree">
                <ul>
                    <li>


                                <span  v-bind:class="`tf-nc btn btn-${node00.users?'primary':'outline-primary'} btn-sm `" data-toggle="modal" data-target="#exampleModal"
                                :title="node00.users.name+','+node00.users.email"
                                
                                >
                                     <img v-if="node00.users" class="" src="/app-assets/images/portrait/small/avatar-s-11.jpg" alt=""> <br>
                                 
                            
                                <span v-if="node00.users">{{node00.users.usercode}}</span>
                                 <span v-else >add</span>
                                    
                                </span>

                    <ul>
                        <li>

                                        <span v-if="node11"  class="tf-nc btn btn-primary btn-sm " @click="viewTree(node11.users.id)"
                                         :title="node11.users.name+','+node11.users.email"
                                        
                                        >                      
                                            <img v-if="node11" class="" :src="node11.users.avatar" alt=""> <br>
                                            {{node11.users.usercode}}                      
                                        </span>

                                         <span v-else  @click="getParentId(node00.id, 'L')" class="tf-nc btn btn-outline-primary btn-sm " data-toggle="modal" data-target="#exampleModal">                      
                                            add                      
                                        </span>

                            
                        
                                <!-- <span @click="getParentId(node00.id, 'L', '10')" v-bind:class="`tf-nc btn btn-${node11?'primary':'outline-primary'} btn-sm `" data-toggle="modal" data-target="#exampleModal">
                                     <img v-if="node11" class="" src="/app-assets/images/portrait/small/avatar-s-11.jpg" alt=""> <br>
                                 

                                 <span v-if="node11" >{{node11.users.usercode}}</span>
                                 <span v-else  >add</span>
                                    
                                </span> -->

                            

                            <ul>
                                <li>
                                
                                        <span v-if="node21"  class="tf-nc btn btn-primary btn-sm " @click="viewTree(node21.users.id)"
                                        :title="node21.users.name+','+node21.users.email"
                                        
                                        >                      
                                            <img v-if="node21" class="" :src="node21.users.avatar" alt=""> <br>
                                            {{node21.users.usercode}}                      
                                        </span>

                                         <span v-else  @click="getParentId(node11.id, 'L')" class="tf-nc btn btn-outline-primary btn-sm " data-toggle="modal" data-target="#exampleModal">                      
                                            add                      
                                        </span>

                                         <ul>
                                                <li>
                                                
                                                <span v-if="node31"  class="tf-nc btn btn-primary btn-sm " @click="viewTree(node31.users.id)"
                                                     :title="node31.users.name+','+node31.users.email"
                                                
                                                >                      
                                                    <img v-if="node31" class="" :src="node31.users.avatar" alt=""> <br>
                                                    {{node31.users.usercode}}                      
                                                </span>

                                                <span v-else  @click="getParentId(node21.id, 'L')" class="tf-nc btn btn-outline-primary btn-sm " data-toggle="modal" data-target="#exampleModal">                      
                                                    add                      
                                                </span>
                                                
                                                </li>
                                                <li>
                                                
                                                <span v-if="node32"  class="tf-nc btn btn-primary btn-sm " @click="viewTree(node32.users.id)"
                                                 :title="node32.users.name+','+node32.users.email"
                                                
                                                >                      
                                                    <img v-if="node32" class="" :src="node32.users.avatar" alt=""> <br>
                                                    {{node32.users.usercode}}                      
                                                </span>

                                                <span v-else  @click="getParentId(node21.id, 'R')" class="tf-nc btn btn-outline-primary btn-sm " data-toggle="modal" data-target="#exampleModal">                      
                                                    add                      
                                                </span>
                                                
                                                </li>
                                        </ul>                                    
                                
                                </li>
                                <li>
                                
                                        <span v-if="node22"  class="tf-nc btn btn-primary btn-sm " @click="viewTree(node22.users.id)"
                                        :title="node22.users.name+','+node22.users.email"
                                        
                                        >                      
                                            <img v-if="node22" class="" :src="node22.users.avatar" alt=""> <br>
                                            {{node22.users.usercode}}                      
                                        </span>

                                         <span v-else  @click="getParentId(node11.id, 'R')" class="tf-nc btn btn-outline-primary btn-sm " data-toggle="modal" data-target="#exampleModal">                      
                                            add                      
                                        </span>

                                        <ul>
                                                <li>
                                                
                                                <span v-if="node33"  class="tf-nc btn btn-primary btn-sm " @click="viewTree(node33.users.id)"
                                                  :title="node33.users.name+','+node33.users.email"
                                                
                                                >                      
                                                    <img v-if="node33" class="" :src="node33.users.avatar" alt=""> <br>
                                                    {{node33.users.usercode}}                      
                                                </span>

                                                <span v-else  @click="getParentId(node22.id, 'L')" class="tf-nc btn btn-outline-primary btn-sm " data-toggle="modal" data-target="#exampleModal">                      
                                                    add                      
                                                </span>
                                                
                                                </li>
                                                <li>
                                                
                                                <span v-if="node34"  class="tf-nc btn btn-primary btn-sm " @click="viewTree(node34.users.id)"
                                                 :title="node34.users.name+','+node34.users.email"
                                                
                                                >                      
                                                    <img v-if="node34" class="" :src="node34.users.avatar" alt=""> <br>
                                                    {{node34.users.usercode}}                      
                                                </span>

                                                <span v-else  @click="getParentId(node22.id, 'R')" class="tf-nc btn btn-outline-primary btn-sm " data-toggle="modal" data-target="#exampleModal">                      
                                                    add                      
                                                </span>
                                                
                                                </li>
                                        </ul>
                                
                                </li>
                            </ul>
                        
                        </li>

                        
                        <li>
                        
                                        <span v-if="node12"  class="tf-nc btn btn-primary btn-sm " @click="viewTree(node12.users.id)"
                                        :title="node12.users.name+','+node12.users.email"
                                        
                                        >                      
                                            <img v-if="node12" class="" :src="node12.users.avatar" alt=""> <br>
                                            {{node12.users.usercode}}                      
                                        </span>

                                         <span v-else  @click="getParentId(node00.id, 'R')" class="tf-nc btn btn-outline-primary btn-sm " data-toggle="modal" data-target="#exampleModal">                      
                                            add                      
                                        </span>

                            

                                <ul>
                                    <li>
                                    
                                            <span v-if="node23"  class="tf-nc btn btn-primary btn-sm " @click="viewTree(node23.users.id)"
                                                :title="node23.users.name+','+node23.users.email"
                                            
                                            >                      
                                                <img v-if="node23" class="" :src="node23.users.avatar" alt=""> <br>
                                                {{node23.users.usercode}}                      
                                            </span>

                                            <span v-else  @click="getParentId(node12.id, 'L')" class="tf-nc btn btn-outline-primary btn-sm " data-toggle="modal" data-target="#exampleModal">                      
                                                add ..                     
                                            </span>

                                                <ul>
                                                    <li>
                                                    
                                                        <span v-if="node35"  class="tf-nc btn btn-primary btn-sm " @click="viewTree(node35.users.id)"
                                                            :title="node35.users.name+','+node35.users.email"
                                                        
                                                        >                      
                                                            <img v-if="node35" class="" :src="node35.users.avatar" alt=""> <br>
                                                            {{node35.users.usercode}}                      
                                                        </span>

                                                        <span v-else  @click="getParentId(node23.id, 'L')" class="tf-nc btn btn-outline-primary btn-sm " data-toggle="modal" data-target="#exampleModal">                      
                                                            add                      
                                                        </span>
                                                    
                                                    </li>
                                                    <li>
                                                    
                                                        <span v-if="node36"  class="tf-nc btn btn-primary btn-sm " @click="viewTree(node36.users.id)"
                                                         :title="node36.users.name+','+node36.users.email"
                                                        
                                                        >                      
                                                            <img v-if="node36" class="" :src="node36.users.avatar" alt=""> <br>
                                                            {{node36.users.usercode}}                      
                                                        </span>

                                                        <span v-else  @click="getParentId(node23.id, 'R')" class="tf-nc btn btn-outline-primary btn-sm " data-toggle="modal" data-target="#exampleModal">                      
                                                            add                      
                                                        </span>

                                            
                                                    
                                                    </li>
                                                </ul>
                                    
                                    </li>
                                    <li>
                                    
                                            <span v-if="node24"  class="tf-nc btn btn-primary btn-sm " @click="viewTree(node24.users.id)"
                                            
                                                     :title="node24.users.name+','+node24.users.email"
                                            >                      
                                                <img v-if="node24" class="" :src="node24.users.avatar" alt=""> <br>
                                                {{node24.users.usercode}}                      
                                            </span>

                                            <span v-else  @click="getParentId(node12.id, 'R')" class="tf-nc btn btn-outline-primary btn-sm " data-toggle="modal" data-target="#exampleModal">                      
                                                add ..                     
                                            </span>                  
                                            <!-- <span @click="getParentId(node12.id)" v-bind:class="`tf-nc btn btn-${node24.users?'primary':'outline-primary'} btn-sm `" data-toggle="modal" data-target="#exampleModal">
                                            <img v-if="node24.users" class="" src="/app-assets/images/portrait/small/avatar-s-11.jpg" alt=""> <br>
                                        

                                        <span v-if="node24.users">{{node24.users.usercode}}</span>
                                        <span v-else >add</span>
                                            
                                        </span> -->
                                

                                            <ul>
                                                <li>
                                                
                                                    <span v-if="node37"  class="tf-nc btn btn-primary btn-sm " @click="viewTree(node37.users.id)"
                                                     :title="node37.users.name+','+node37.users.email"
                                                    
                                                    >                      
                                                        <img v-if="node37" class="" :src="node37.users.avatar" alt=""> <br>
                                                        {{node37.users.usercode}}                      
                                                    </span>

                                                    <span v-else  @click="getParentId(node24.id, 'L')" class="tf-nc btn btn-outline-primary btn-sm " data-toggle="modal" data-target="#exampleModal">                      
                                                        add                      
                                                    </span>
                                                
                                                </li>
                                                <li>
                                                
                                                         <span v-if="node38"  class="tf-nc btn btn-primary btn-sm " @click="viewTree(node38.users.id)"
                                                            :title="node38.users.name+','+node38.users.email"
                                                         
                                                         >                      
                                                            <img v-if="node38" class="" :src="node38.users.avatar" alt=""> <br>
                                                            {{node38.users.usercode}}             
                                                        </span>

                                                        <span v-else  @click="getParentId(node24.id, 'R')" class="tf-nc btn btn-outline-primary btn-sm " data-toggle="modal" data-target="#exampleModal">                      
                                                            add                      
                                                        </span>
                                                
                                                </li>
                                            </ul>
                                    
                                    </li>
                                </ul>
                        
                        </li>
                    </ul>

                    </li>
                </ul>
                </div>
                      
                
            </div>

            <div v-else class="container py-5 text-center mt-5">
                <div class="pt-5"></div>
                <h3>Initializing...</h3>


                <button v-if="loading" class="btn btn-primary ">Initializing Node...</button>
                <button v-else @click="initiateNode()" class="btn btn-primary ">Load</button>
            </div>

            <!-- Button trigger modal -->
                <button type="button" class="d-none btn btn-outline-primary btn-sm" data-toggle="modal" data-target="#exampleModal">
              view
                </button>

                <!-- Modal -->
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div style="max-width: 230px;" class="modal-content mx-auto">
                    <div class="modal-header">
                        
                        <h5 class="modal-title" id="exampleModalLabel">Select Referrals</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div  class="modal-body">
                        <div class="form-group text-center">
                          <select v-model="selected_user" class="form-control">

                            
                              <option 
                              v-for="my_referral in my_referrals" 
                              :key="my_referral.index" 
                              :value="my_referral.referree_id">

                              <span :title="my_referral.referree.name">
                                  {{my_referral.referree.usercode}} 
                                  [{{my_referral.referree.username}}]
                                  
                                  </span>
                              </option>
                            
                          </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                        <button @click="createNode()" type="button" class="btn btn-warning">Append Downline</button>
                    </div>
                    </div>
                </div>
                </div>

    </div>


</template>


<script>
import axios from 'axios'
export default {


    data() {
        return {

            node00: null,
            node11: null,
            node12: null,
            node21: null,
            node22: null,
            node23: null,
            node24: null,
            node31: null,
            node32: null,
            node33: null,
            node34: null,
            node35: null,
            node36: null,
            node37: null,
            node38: null,

            loading: false,

            selected_parent_id: '',
            selected_position: '',
            selected_legs:'',
            selected_user: '',

            my_referrals: []

            
   
        }
    },

    methods: {

        viewTree(id){

            // alert('push')

            this.$router.push('/admin/AdminNodeGenealogy/'+id)


                  this.getUserData()
                    this.getReferrals()
                    this.getNodes()



        
        },

        
        getUserData(){

           this.userData = JSON.parse(localStorage.getItem('user_data'))

        //    alert('gotten')

           console.log(this.userData)

           


        },
        getParentId(selected_parent_id, selected_position, selected_legs ){
            this.selected_parent_id = selected_parent_id
            this.selected_position = selected_position
            this.selected_legs = selected_legs
        },
        getNodes(){

            axios({
                url: process.env.VUE_APP_URL+'/api/node_descendants_test',
                method: 'get',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' +localStorage.getItem('user_token')
                },
                params:{
                    user_id: this.$route.params.id
                }

            })
            .then((response)=>{

                // alert(this.$route.params.id)

                console.log(response)

                this.node00 = response.data.node00
                this.node11 = response.data.node11
                this.node12 = response.data.node12
                this.node21 = response.data.node21
                this.node22 = response.data.node22
                this.node23 = response.data.node23
                this.node24 = response.data.node24
                this.node31 = response.data.node31
                this.node32 = response.data.node32
                this.node33 = response.data.node33
                this.node34 = response.data.node34
                this.node35 = response.data.node35
                this.node36 = response.data.node36
                this.node37 = response.data.node37
                this.node38 = response.data.node38

         
            })
            .catch((response)=>{

                console.log(response)
            })

        },
        initiateNode(){

            this.loading = true
            axios({
                url: process.env.VUE_APP_URL + '/api/create_parent_node',
                method: 'post',
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('user_token')
                },
                data:{
                    position: 'L',
                    legs: '11',
                    package_id: '1'
                }
            })
            .then((response)=>{
                this.loading = false
                console.log(response)
            })
            .catch((response)=>{
                console.log(response)
            })
        },
        createNode(){
            alert(this.selected_parent_id)
            alert(this.selected_user)
            alert(this.selected_position)
            alert(this.selected_legs)

            axios({
                url: process.env.VUE_APP_URL + '/api/append_node',
                method: 'post',
                headers:{
                    'Authorization': 'Bearer '+localStorage.getItem('user_token')
                },
                data:{
                    parent_id: this.selected_parent_id,
                    user_id: this.selected_user,
                    position: this.selected_position,
                    legs: this.selected_legs,
                    
                }
            }).then((response)=>{

                this.$vToastify.success({
                            'body': response.data['message'],
                            'iconEnabled': true,
                            'defaultTitle': false,
                            'hideProgressbar': true                                        
                        });

                        this.getNodes()
                        this.getReferrals()


                console.log(response)

            }).catch((response)=>{
                console.log(response)
            })
        },

        getReferrals(){
            axios({
                url: process.env.VUE_APP_URL + '/api/getReferrals',
                method: 'get',
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('user_token')
                },
                params: {
                    user_id: this.userData.id
                }
            })
            .then((response)=>{

                this.my_referrals = response.data

                console.log(this.my_referrals)
            })
            .catch((response)=>{

                console.log(response)
            })
        }
    },

    mounted() {

        
        this.getUserData()
        this.getReferrals()
        this.getNodes()

    },
    
}
</script>

<style scoped>

.tf-nc img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;

 
}
    .tf-nc:before,
    .tf-nc:after {
    /* css here */
    border: 2px solid white;
    border-radius: 56px;
    }
    li li:before {
    /* css here */

    border: 2px solid white;
     border-radius: 56px;
    }
</style>
