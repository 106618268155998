<template>
<div class="">

    <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">Dashboard</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">Profile</a>
                            </li>
                            <!-- <li class="breadcrumb-item"><a href="#">Pages</a>
                            </li>
                            <li class="breadcrumb-item active">FAQ
                            </li> -->
                        </ol>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
    
    <div  class="content-body">


        <div class="card">
            <div class="card-body">

                <div class="row">

                    <div class="col-md-12">
                        <div class="form-group mx-auto text-center">
                        <img v-if="avatar"
                        onclick="document.getElementById('customFile').click()" 
                        id="previewImg" 
                        style="height: 200px; width: 200px; object-fit: cover; border-radius: 50%;" 
                        class="shadow" 
                        :src="avatar" >

                        <img v-else
                        onclick="document.getElementById('customFile').click()" 
                        id="previewImg" 
                        style="height: 200px; width: 200px; object-fit: cover; border-radius: 50%;" 
                        class="shadow" 
                        :src="'https://www.lifewire.com/thmb/8MhWKwi4GEGiYRT6P56TBvyrkYA=/1326x1326/smart/filters:no_upscale()/cloud-upload-a30f385a928e44e199a62210d578375a.jpg'" >

                        </div> 
                        <div class="text-center d-none">
                            <input @change="previewFile4" ref="file" type="file" id="customFile"  >
                            
                        </div>

                        <div class="form-group text-center">
                            <button @click="uploadAvatar()" class="btn btn-primary btn-sm">Upload</button>
                        </div>
                    </div>


                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">Fullname:</label>
                            <input v-model="name" type="text" class="form-control">
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">Email:</label>
                            <input v-model="email" type="text" class="form-control">
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">Phone</label>
                            <input v-model="phone" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">Gender</label>
                            <div class="custom-control custom-radio mb-1">
                                <input type="radio" id="customRadio1" v-model="gender" :value="'male'" class="custom-control-input" :selected="gender == 'male'?'yes':''">
                                <label class="custom-control-label" for="customRadio1">Male</label>
                            </div>
                            <div class="custom-control custom-radio mb-1">
                                <input type="radio" id="customRadio2" v-model="gender" :value="'female'" class="custom-control-input" :selected="gender == 'female'?'yes':''">
                                <label class="custom-control-label" for="customRadio2">Female</label>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="form-group">
                    <label for="">Residential Address</label>
                    <textarea class="form-control" v-model="residential_address"   cols="30" rows="5" placeholder="Residential Address"></textarea>
                </div>
                

             <div class="container py-3">

                <div class="row">
                    <div class="col-md-5">
                        <hr>
                    </div>
                    <div class="col-md-2 text-center">
                        Next of Kin Details
                    </div>
                    <div class="col-md-5">
                        <hr>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">Next of Kin Name</label>
                            <input v-model="nok_name" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">Next of Kin Phone</label>
                            <input v-model="nok_phone" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="">Next of Kin Address</label>
                            <textarea v-model="nok_address" class="form-control" id="" cols="30" rows="5"></textarea>
                        </div>
                    </div>
                </div>


             </div>

            <div class="container py-3">

                <div class="row">
                    <div class="col-md-5">
                        <hr>
                    </div>
                    <div class="col-md-2 text-center">
                        Bank Details
                    </div>
                    <div class="col-md-5">
                        <hr>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">


                        <div class="form-group">
                            <label for="">Bank Name:</label>
                            <select v-model="bank_name" class="form-control"  >
                           
                                <option >Access Bank</option>
                                <option >  Citibank</option>
                                <option >Ecobank</option>
                                <option >First City Monument Bank (FCMB)</option>
                                <option >Fidelity Bank</option>
                                <option >First Bank</option>
                     
                                <option >Guaranty Trust Bank (GTB)</option>
                                <option >Heritage Bank</option>
                                <option >Jaiz Bank</option>
                                <option >Keystone Bank</option>
                                <option >Parallex Bank</option>
                                <option >Providus Bank</option>
                                <option >Stanbic IBTC Bank</option>
                                <option >Skye Bank</option>
                                <option >Standard Chartered Bank</option>
                                <option >Sterling Bank</option>
                                <option >Suntrust Bank</option>
                                <option >Titan Trust Bank</option>
                                <option >Union Bank</option>
                                <option >United Bank for Africa (UBA)</option>
                                <option >Unity Bank</option>
                                <option >Wema Bank</option>
                                <option >Zenith Bank</option>
                            </select>
                        </div>


                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">Account Name:</label>
                            <input v-model="account_name" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">Account Number</label>
                            <input v-model="account_no" class="form-control" id="" >
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">Sort Code: (Optional)</label>
                            <input v-model="sort_code" class="form-control" id="" >
                        </div>
                    </div>
                </div>

                <div class="row py-5">
                    <div class="col-md-6">

                    </div>
                    <div class="col-md-6">
                        <button @click="updateProfile()" class="btn btn-primary shadow btn-block">Update</button>
                    </div>
                </div>


             </div>


            </div>
        </div>



        
    </div>

</div>


</template>


<script>

import axios from 'axios'
export default {

    data() {
        return {
            userData: [],
            residential_address: '',
            phone: '',
            gender: '',
            nok_name: '',
            nok_address: '',
            nok_phone: '',
            bank_code: '',
            bank_name: '',
            auth_code: '',
            account_name: '',
            account_no: '',
            sort_code: '',
            avatar: null,
            name: '',
            email: '',
            
        }
    },


    methods:{

        getBankData(e) {

            this.bank_code = e.target.value

            this.bank_name = e.target.selectedOptions[0].innerText

            return this.bank_name

            // console.log(e.target.selectedOptions[0].innerText);
            
        },

        previewFile4(event){


            console.log(event)

               if(event.target.files.length > 0){
                var src = URL.createObjectURL(event.target.files[0]);
                var preview = document.getElementById("previewImg");
                preview.src = src;
                // preview.style.display = "block";
            }

             this.file = this.$refs.file.files[0];

        },

        uploadAvatar(){
            let formData = new FormData();

            formData.append('avatar', this.file);
            formData.append('type', 'avatar');

            axios({
                url: process.env.VUE_APP_URL + '/api/profiles',
                method: 'post',
                data: formData,
                headers: {
                    'Authorization': 'Bearer ' +localStorage.getItem('user_token'),
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) =>{

                // toast.success('Profile picture Updated');

                console.log(response)
            })
            .catch((response) =>{

                console.log(response)
            })


        },

        getUserData(){

            let loader = this.$loading.show({
                  // Optional parameters
                  container: this.fullPage ? null : this.$refs.formContainer,
                  canCancel: true,
                  color: '#7367F0',
                  loader: 'bars',
                  onCancel: this.onCancel,
                  opacity: 0.3
                });

                // alert('get profie')

             axios({
                url: process.env.VUE_APP_URL+'/api/profiles',
                method: 'get',
                 headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' +localStorage.getItem('user_token')
                },
            })
            .then((response)=>{

                if(response.data.profile){

                    this.nok_address = response.data.profile.nok_address,
                    this.nok_phone = response.data.profile.nok_phone,
                    this.nok_name = response.data.profile.nok_name,
                    this.bank_code = response.data.profile.bank_code,
                    this.bank_name = response.data.profile.bank_name,
                    this.account_name = response.data.profile.account_name,
                    this.account_no = response.data.profile.account_no
                    this.phone = response.data.profile.phone
                    this.gender = response.data.profile.gender


                }

                
                    
                    this.name = response.data.name,
                    this.email = response.data.email,
                    this.avatar = response.data.avatar,

                    // alert(this.avatar)



            loader.hide()

                console.log(response)
            })
            .catch((response)=>{

                  loader.hide()

                console.log(response)
            })


        },

        updateProfile(){

            // alert(this.bank_name)

             let loader = this.$loading.show({
                  // Optional parameters
                  container: this.fullPage ? null : this.$refs.formContainer,
                  canCancel: true,
                  color: '#7367F0',
                  loader: 'bars',
                  onCancel: this.onCancel,
                  opacity: 0.3
                });
                
             axios({
                url: process.env.VUE_APP_URL+'/api/profiles',
                method: 'post',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' +localStorage.getItem('user_token')
                },
                data:{
                    residential_address: this.residential_address,
                    nok_address: this.nok_address,
                    nok_name: this.nok_name,
                    nok_phone: this.nok_phone,
                    bank_code: this.bank_code,
                    bank_name: this.bank_name,
                    account_name: this.account_name,
                    account_no: this.account_no,
                    name: this.name,
                    email: this.email,
                    gender: this.gender,
                    phone: this.phone,

                }

            })
            .then((response)=>{

            loader.hide()

             this.$vToastify.success({
                                        'body': 'Update Successful.',
                                        'iconEnabled': true,
                                        'defaultTitle': false,
                                        'hideProgressbar': true                                        
                                    });

                console.log(response)
            })
            .catch((response)=>{

                    loader.hide()

                console.log(response)
            })


        }

        
    },

    mounted() {
        this.getUserData()
    },
    
}
</script>