<template>
<div class="">

    <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">Dashboard</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">Stairsteps</a>
                            </li>
                            <!-- <li class="breadcrumb-item"><a href="#">Pages</a>
                            </li>
                            <li class="breadcrumb-item active">FAQ
                            </li> -->
                        </ol>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
    
    <div  class="content-body">

            <div class="col-xl-12 col-md-12 col-12">
                            <div class="card ">

                                <div class="card-body text-center">
                                    <h4>Stair Steps</h4>

                                     <ul class="nav nav-pills mb-3 mx-auto " id="pills-tab" role="tablist">
                                    <li class="nav-item text-center" role="presentation">
                                        <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Jade</a>
                                    </li>
                                    <li class="nav-item text-center" role="presentation">
                                        <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Opal</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Onyx</a>
                                    </li>
                                     <li class="nav-item text-center" role="presentation">
                                        <a class="nav-link" id="pills-zircon-tab" data-toggle="pill" href="#pills-zircon" role="tab" aria-controls="pills-zircon" aria-selected="false">Zircon</a>
                                    </li>
                                     <li class="nav-item text-center" role="presentation">
                                        <a class="nav-link" id="pills-director-tab" data-toggle="pill" href="#pills-director" role="tab" aria-controls="pills-director" aria-selected="false">Director</a>
                                    </li>
                                    </ul>
                                    <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active text-center" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                                        <div class="stair-logo pt-3">
                                            <img class="package_icon" src="/stair_icons/phoenix-09.png" alt="">
                                        </div>


                                        <div class="row">
                                            <div class="col-6">
                                            <h6>Left Group <br> Emerald Packages</h6>
                                            <h6>1/5</h6>
                                            
                                                <div class="progress progress-bar-primary box-shadow-6">
                                                
                                                <div class="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="1" aria-valuemax="5"  style="width:10%" aria-describedby="example-caption-3"></div>
                                                </div>

                                            </div>

                                            <div class="col-6">
                                            <h6>Right Group <br> Emerald Packages</h6>
                                            <h6>1/5</h6>
                                                <div class="progress progress-bar-primary box-shadow-6">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="1" aria-valuemax="5" style="width:12%" aria-describedby="example-caption-3"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="text-center py-3">
                                            <button class="btn btn-primary">view more</button>
                                        </div>

                                        

                                    </div>
                                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">

                                                        <div class="stair-logo ">
                                                            <img class="package_icon" src="/stair_icons/phoenix-15.png" alt="">
                                                        </div> 
                                        ...
                                    </div>
                                    <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">

                                                        <div class="stair-logo ">
                                                            <img class="package_icon" src="/stair_icons/phoenix-21.png" alt="">
                                                        </div> 
                                        ...
                                    </div>
                                
                                      <div class="tab-pane fade" id="pills-zircon" role="tabpanel" aria-labelledby="pills-zircon-tab">

                                                            <div class="stair-logo ">
                                                            <img class="package_icon" src="/stair_icons/phoenix-27.png" alt="">
                                                        </div>
                                          ...
                                        </div>
                                    

                                      <div class="tab-pane fade" id="pills-director" role="tabpanel" aria-labelledby="pills-zircon-tab">

                                                            <div class="stair-logo ">
                                                            <img class="package_icon" src="/stair_icons/phoenix-37-1.png" alt="">
                                                        </div>
                                          ...
                                        </div>
                                    </div>
                                </div>
                               
                               
                               
                            </div>
                        </div>
        
    </div>

</div>


</template>


<script>
export default {
    
}
</script>

<style scoped>
   .package_icon {
           
            height: 60px;
        }
        
</style>