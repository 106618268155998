import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import pusher from 'pusher-js'
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import VueToastify from "vue-toastify";
import VueNumberFormat from 'vue-number-format'
import LoadScript from 'vue-plugin-load-script';
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'


Chartkick.options = {
  backgroundColor: '#aa3',
  plugins: {
    legend: {
        display: true,
        labels: {
            color: 'red'
        }
    }
}
}


// Vue.use(LoadScript);


require('vue2-animate/dist/vue2-animate.min.css')



Vue.config.productionTip = false

// Vue.use(VueAxios, axios)

Vue.use(Loading);
Vue.use(VueToastify);

Vue.use(Chartkick.use(Chart))

new Vue({
  router,
  store,
  VueAxios,
  VueNumberFormat,
  LoadScript,
  axios,
  pusher,

  render: h => h(App)
}).$mount('#app')
