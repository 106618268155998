<template >
    <div>
            <div class="main-menu-content">
            <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">

                <li class=" navigation-header">
                    <span>Admin Dashboard</span>
                    
                </li>

                <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/admin/home">
                       <i class="fa-solid fa-gauge"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">Dashboard</span>
                    </router-link>
                </li>

                 <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/admin/members">
                      <i class="fa-solid fa-users"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">Members</span>
                    </router-link>
                </li>
               
                <!-- <li class=" nav-item"><a class="d-flex align-items-center" href="#"><i data-feather="file-text"></i><span class="menu-title text-truncate" data-i18n="Invoice">Invoice</span></a>
                    <ul class="menu-content">
                        <li><a class="d-flex align-items-center" href="app-invoice-list.html"><i data-feather="circle"></i><span class="menu-item text-truncate" data-i18n="List">List</span></a>
                        </li>
                        <li><a class="d-flex align-items-center" href="app-invoice-preview.html"><i data-feather="circle"></i><span class="menu-item text-truncate" data-i18n="Preview">Preview</span></a>
                        </li>
                        <li><a class="d-flex align-items-center" href="app-invoice-edit.html"><i data-feather="circle"></i><span class="menu-item text-truncate" data-i18n="Edit">Edit</span></a>
                        </li>
                        <li><a class="d-flex align-items-center" href="app-invoice-add.html"><i data-feather="circle"></i><span class="menu-item text-truncate" data-i18n="Add">Add</span></a>
                        </li>
                    </ul>
                </li> -->

                <!-- <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/admin/phyx-news">
                      <i data-feather="save"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">PHX News</span>
                    </router-link>
                </li> -->

                <!-- <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/admin/orders">
                      <i data-feather="save"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">Orders</span>
                    </router-link>
                </li> -->

                <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/admin/phoenixgn-packages">
                      <i class="fa-solid fa-box-open"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">Packages</span>
                    </router-link>
                </li>

                  <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/Admin/Genealogy">
                       <i class="fa-solid fa-sitemap"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">Genealogy</span>
                    </router-link>
                </li>

                <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/Admin/Earnings">
                       <i class="fa-solid fa-wallet"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">Earnings</span>
                    </router-link>
                </li>

                <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/admin/transactions">
                      <i class="fa-solid fa-coins"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">Transactions</span>
                    </router-link>
                </li>

                <!-- <li class=" nav-item"><a class="d-flex align-items-center" href="#"><i data-feather="file-text"></i><span class="menu-title text-truncate" data-i18n="Invoice">Payouts</span></a>
                    <ul class="menu-content">
                        <li><a class="d-flex align-items-center" to="/admin/allpayouts"><i data-feather="circle"></i><span class="menu-item text-truncate" data-i18n="List">All Payouts</span></a>
                        </li>
                        <li><a class="d-flex align-items-center" href="/admin/completed-payouts"><i data-feather="circle"></i><span class="menu-item text-truncate" data-i18n="Preview">Completed Payouts</span></a>
                        </li>
                        <li><a class="d-flex align-items-center" href="/admin/cancelled-payouts"><i data-feather="circle"></i><span class="menu-item text-truncate" data-i18n="Edit">Cancelled Payouts</span></a>
                        </li>
                      
                    </ul>
                </li> -->

   

                <li class=" navigation-header">
                    <span>Management</span>
                    
                </li>

                <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/admin/phx-stores">
                      <i class="fa-solid fa-store"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">Store</span>
                    </router-link>
                </li>

                 <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/admin/phx-stores">
                      <i class="fa-solid fa-newspaper"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">PHX News</span>
                    </router-link>
                </li>

                 <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/admin/phx-stores">
                      <i class="fa-solid fa-circle-question"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">FAQ</span>
                    </router-link>
                </li>

                <!-- <li class=" nav-item"><a class="d-flex align-items-center" href="#"><i data-feather="file-text"></i><span class="menu-title text-truncate" data-i18n="Invoice">Resources</span></a>
                    <ul class="menu-content">
                        <li><a class="d-flex align-items-center" href="/Admin/FAQ"><i data-feather="circle"></i><span class="menu-item text-truncate" data-i18n="List">View</span></a>
                        </li>
                        <li><a class="d-flex align-items-center" href="app-invoice-preview.html"><i data-feather="circle"></i><span class="menu-item text-truncate" data-i18n="Preview">Edit</span></a>
                        </li>
                       
                      
                    </ul>
                </li> -->

              

                <!-- <li class=" navigation-header">
                    <span>Resources</span>
                    
                </li> -->

<!--       

                <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/Admin/StairSteps">
                      <i data-feather="save"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">Stair Steps</span>
                    </router-link>
                </li> -->


                <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/Admin/Notifications">
                      <i class="fa-solid fa-bell"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">Notifications</span>
                    </router-link>
                </li>

                <li class=" nav-item ">
                
                    <router-link class="d-flex align-items-center" to="/sign">
                      <i class="fa-solid fa-right-from-bracket"></i>
                        <span class="menu-title text-truncate" data-i18n="Logout">Logout</span>
                    </router-link>
                </li>
              
               
            </ul>
        </div>

    </div>
</template>
<script>
export default {
    name: 'AdminSidebar',
    components:{

    },
    data() {
        return {
            
        }
    },
}
</script>