<template>
<div class="">

    <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">Dashboard</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">Investments</a>
                            </li>
                            <!-- <li class="breadcrumb-item"><a href="#">Pages</a>
                            </li>
                            <li class="breadcrumb-item active">FAQ
                            </li> -->
                        </ol>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
    
    <div  class="content-body">
        
    </div>

</div>


</template>


<script>
export default {
    
}
</script>