<template>
<div class="">

    <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">Dashboard</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">Earnings</a>
                            </li>
                            <!-- <li class="breadcrumb-item"><a href="#">Pages</a>
                            </li>
                            <li class="breadcrumb-item active">FAQ
                            </li> -->
                        </ol>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
    
    <div  class="content-body">
       

        <div class="car">
            <div class="card-bod">


                <div class="col-md-6 py-2">
                    <div class="card">
                        <div class="card-body">
                            <h3 class="text-success">$ {{formatDigits(wallet_balance/500)}}</h3>
                            <h6 class="text-warning">Total Wallet Balance</h6>
                        </div>
                    </div>
                </div>

                <ul class="nav nav-pills mb-3 nav-fill" id="pills-tab" role="tablist">
  <li class="nav-item" role="presentation">
    <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Commissions</a>
  </li>
  <li class="nav-item" role="presentation">
    <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Stair Steps</a>
  </li>
  <!-- <li class="nav-item" role="presentation">
    <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Contact</a>
  </li> -->
</ul>
<div class="tab-content" id="pills-tabContent">
  <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
      
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left   " type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <h4 class="text-warning">Direct Referral</h4>
                                    <h6>Total Balance: $ {{formatDigits(direct_referral_sum/500)}}</h6>
                                </button>
                            </h2>
                            </div>

                            
                                    <!-- second_gen_indr_sum
                                    third_gen_indr_sum
                                    three_one_sum -->

                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div class="card-body table-responsive">
                                <p class="py-1">
                                    Direct Referral Commission means the commission you personally refer into Phoenix.
                                </p>
                                <table class="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Account</th>
                                            
                                            <th>Package</th>
                                            <th>Bonus</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody v-if="direct_referrals.length > 0">
                                        <tr v-for="direct_referral, key in direct_referrals" :key="key">
                                            <td>{{key + 1}}</td>
                                            <td>
                                                {{direct_referral.referree.name}} <br>
                                                {{direct_referral.referree.email}} <br>
                                                <span class="badge badge-primary">{{direct_referral.referree.usercode}}</span>
                                            </td>

                                        
                                            <td>
                                                            <!-- <img style="height: 30px; min-widht: 30px;" class="shadow" :src="direct_referral.
                                                            .package.featured_logo??''" alt=""> <br> -->
                                                            {{direct_referral.referree.package?direct_referral.referree.package.title:''}}
                                                    
                                            </td>
                                            <td>$ <span class="text-white">{{formatDigits(direct_referral.referrer_bonus/500)}}</span></td>
                                            
                        
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <h4 class="text-warning">Indirect Referral</h4>
                                <h6>Total Balance: $ {{formatDigits((second_gen_indr_sum + third_gen_indr_sum)/500)}}</h6>
                                </button>
                            </h2>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                            <div class="card-body table-responsive">
                                <p class="py-1">
                                    Indirect Referral Commission means the commission you earn when those you refer also refer another members into Phoenix.
                                </p>
                                <div v-if="loading" class="py-5">
                                    <h6 class="text-white text-center mt-5">Loading...</h6>
                                </div>
                                <div v-else class="c">

                                    <h6 class="font-weight-bold py-3">Second Generation</h6>
                                    <table  class="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Account</th>
                                            
                                            <th>Package</th>
                                            <th>Bonus</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody v-if="second_gen_indr_data.length > 0">
                                        <tr v-for="second_gen_indr, key in second_gen_indr_data" :key="key">
                                            <td>{{key + 1}}</td>
                                            <td>
                                                {{second_gen_indr.referral.name}} <br>
                                                {{second_gen_indr.referral.email}} <br>
                                                <span class="badge badge-primary">{{second_gen_indr.referral.usercode}}</span>
                                            </td>

                                        
                                            <td>
                                                            <!-- <img style="height: 30px; min-widht: 30px;" class="shadow" :src="second_gen_indr.referral.package.featured_logo??''" alt=""> <br> -->
                                                            {{second_gen_indr.referral.package.title}}
                                                    
                                            </td>
                                            <td>$ <span class="text-white">{{formatDigits((second_gen_indr.bonus)/500)}}</span></td>
                                            
                        
                                        </tr>
                                    </tbody>
                                </table>


                                    <h6 class="font-weight-bold py-3">Third Generation</h6>

                                <table  class="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Account</th>
                                            
                                            <th>Package</th>
                                            <th>Bonus</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody v-if="third_gen_indr_data.length > 0">
                                        <tr v-for="third_gen_indr, key in third_gen_indr_data" :key="key">
                                            <td>{{key + 1}}</td>
                                            <td>
                                                {{third_gen_indr.referral.name}} <br>
                                                {{third_gen_indr.referral.email}} <br>
                                                <span class="badge badge-primary">{{third_gen_indr.referral.usercode}}</span>
                                            </td>

                                        
                                            <td>
                                                            <!-- <img style="height: 30px; min-widht: 30px;" class="shadow" :src="third_gen_indr.referral.package.featured_logo??''" alt=""> <br> -->
                                                            {{third_gen_indr.referral.package.title}}
                                                    
                                            </td>
                                            <td>$ <span class="text-white">{{formatDigits((third_gen_indr.bonus)/500)}}</span></td>
                                            
                        
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingThree">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <h4 class="text-warning">3 in 1 Commission</h4>
                                <h6>Total Balance: $ {{formatDigits(third_gen_indr_sum/500)}}</h6>
                                </button>
                            </h2>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div class="card-body table-responsive">

                                <p class="py-1">
                                    3 in 1 commission means the commission you get when you register 3 members in 7 days.
                                </p>
                                
                            
                                <table  class="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>1st Account</th>

                                            <th>2nd Account</th>

                                            <th>3rd Account</th>

                                            <th>Date</th>
                                            
                                            
                                            <th>Bonus</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody  v-if="three_one_data.length > 0">
                                        <tr v-for="three_one, key in three_one_data" :key="key">
                                            <td>{{key + 1}}</td>
                                            <td>
                                                {{three_one.first_referral.name}} <br>
                                                {{three_one.first_referral.email}} <br>
                                                <span class="badge badge-primary">{{three_one.first_referral.usercode}}</span> <br>
                                                <!-- <img style="height: 30px; min-widht: 30px;" class="shadow" :src="three_one.first_referral.package.featured_logo??''" alt=""> <br> -->
                                                {{three_one.first_referral.package?three_one.first_referral.package.title:'no-package'}}
                                            </td>

                                            <td>
                                                {{three_one.second_referral.name}} <br>
                                                {{three_one.second_referral.email}} <br>
                                                <span class="badge badge-primary">{{three_one.second_referral.usercode}}</span> <br>
                                                <!-- <img style="height: 30px; min-widht: 30px;" class="shadow" :src="three_one.second_referral.package.featured_logo??''" alt=""> <br> -->
                                                {{three_one.second_referral.package?three_one.second_referral.package.title:'no-package'}}
                                            </td>

                                            <td>
                                                {{three_one.third_referral.name}} <br>
                                                {{three_one.third_referral.email}} <br>
                                                <span class="badge badge-primary">{{three_one.third_referral.usercode}}</span> <br>
                                                <!-- <img style="height: 30px; min-widht: 30px;" class="shadow" :src="three_one.third_referral.package.featured_logo??''" alt=""> <br> -->
                                                {{three_one.third_referral.package.title}}
                                            </td>

                                            <td>{{three_one.created_at}}</td>

                                            <td>$ <span class="text-white">{{formatDigits((three_one.indirect_bonus)/500)}}</span></td>
                                            
                        
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFour">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <h4 class="text-warning">Matching Pair Commission</h4>
                                <h6>Total Balance: $ {{formatDigits(current_points/500)}}</h6>
                                </button>
                            </h2>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                 <p class="py-2">
                                    This is the commission you earn when a package on the left pair with another on the right.
                                </p>
                            <div v-if="current_points == null?'':'d-none'" class="card-body">

                               

                                <div class="con text-center">
                                    
                                    <div class="tf-tree">
                                        <ul>
                                            <li>
                                            <span class="tf-nc btn btn-primary ">USERCODE</span>
                                            <ul>
                                                <li style="max-width: 200px;"><span class="tf-nc btn btn-primary ">LEFT PAIR</span></li>
                                                <li style="max-width: 200px;"><span class="tf-nc btn btn-primary ">RIGHT PAIR</span></li>
                                            </ul>
                                            </li>
                                        </ul>
                                    </div>

                                </div>

                            <div style="font-size: 97%;" class="">
                                    <div class="row">
                                    <div class="col-6 border-right-primary ">
                                        <div class="card table-responsive">
                                            <div class="card-body table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            
                                                            <th>Accounts</th>

                                                            <th class="d-none d-md-block d-md-none">Point</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody  v-if="left_generations.length > 0">
                                                        <tr v-for="left_generation, key in left_generations" :key="key">
                                                            <td>
                                                                {{left_generation.users.usercode}} <br>
                                                                {{left_generation.users.email}} <br>
                                                                <!-- <img style="height: 30px; min-widht: 30px;" class="shadow" :src="left_generation.packages.featured_logo??''" alt=""> <br> -->
                                                                {{left_generation.packages.title}}
                                                            </td>
                                                            
                                                            <td class="">{{left_generation.packages.points}}</td>
                                                        
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        
                                    </div>
                                
                                    <div class="col-6 border-left-primary ">
                                        <div class="card table-responsive">
                                            <div class="card-body table-responsive">
                                               <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            
                                                            <th>Accounts</th>

                                                            <th class="d-none d-md-block d-md-none">Point</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody  v-if="right_generations.length > 0">
                                                        <tr v-for="right_generation, key in right_generations" :key="key">
                                                            <td>
                                                                {{right_generation.users.usercode}} <br>
                                                                {{right_generation.users.email}} <br>
                                                                <!-- <img style="height: 30px; min-widht: 30px;" class="shadow" :src="right_generation.packages.featured_logo??''" alt=""> <br> -->
                                                                {{right_generation.packages.title}}
                                                            </td>
                                                            
                                                            <td class="">{{right_generation.packages.points}}</td>
                                                        
                                                        </tr>
                                                    </tbody>
                                                </table>  
                                        </div>
                                        </div>

                                    </div>
                                    </div>
                            </div>

                            

                            
                            </div>
                            <div style="height: 130px;" class="card-body py-3 text-center">

                                <h4>No records found...</h4>

                            </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFour">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <h4 class="text-warning">Phoenix Reward</h4>
                                <h6>Total Balance: $0.00</h6>
                                </button>
                            </h2>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                            <div class="card-body table-responsive">
                                <p class="py-2">
                                    Every members that register with a Diamond Package is entitle to weekly #500 while Family Package are entitle to weekly commission of #500 & #1500 every Sunday for life
                                </p>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Fullname</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Bonus</th>
                                            
                                            
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Jane doe</td>
                                            <td>jane@mail.om</td>
                                            <td>08033223344</td>
                                            <td>$ 0.00</td>
                                            
                                            <td>
                                                <button class="btn btn-warning btn-sm">view more</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </div>

                        
                    </div>    
    </div>
  <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">

                          <div class="card ">

                                <div class="card-body text-center">
                                    <h4>Stair Steps</h4>

                                     <ul class="nav nav-pills mb-3 nav-justified" id="pills-tab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link active " id="jade-tab" data-toggle="pill" href="#jade" role="tab" aria-controls="jade" aria-selected="true">Jade</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Opal</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Onyx</a>
                                    </li>
                                     <li class="nav-item" role="presentation">
                                        <a class="nav-link" id="pills-zircon-tab" data-toggle="pill" href="#pills-zircon" role="tab" aria-controls="pills-zircon" aria-selected="false">Zircon</a>
                                    </li>
                                     <li class="nav-item" role="presentation">
                                        <a class="nav-link" id="pills-director-tab" data-toggle="pill" href="#pills-director" role="tab" aria-controls="pills-director" aria-selected="false">Director</a>
                                    </li>
                                    </ul>
                                    <div class="tab-content" id="pills-tabContent">

                                    <div class="tab-pane fade show active text-center" id="jade" role="tabpanel" aria-labelledby="jade-tab">

                                        <div class="stair-logo ">
                                            <img class="package_icon" src="/stair_icons/phoenix-09.png" alt="">
                                        </div>


                                        <div class="row">
                                            <div class="col-6 border">
                                            <h6>Left Group <br> Emerald Packages</h6>
                                            <h6>{{jade_record.left_count}}/5</h6>
                                                <div class="avatar-group d-none">
                                                    <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Billy Hopkins" class="avatar pull-up">
                                                        <img src="/packages/emerald_package.png" alt="Avatar" width="43" height="33">
                                                    </div>
                                                    <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Amy Carson" class="avatar pull-up">
                                                        <img src="/packages/emerald_package.png" alt="Avatar" width="43" height="33">
                                                    </div>
                                                    <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Brandon Miles" class="avatar pull-up">
                                                        <img src="/packages/emerald_package.png" alt="Avatar" width="43" height="33">
                                                    </div>
                                                    <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Daisy Weber" class="avatar pull-up">
                                                        <img src="/packages/emerald_package.png" alt="Avatar" width="43" height="33">
                                                    </div>
                                                    <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Jenny Looper" class="avatar pull-up">
                                                        <img src="/packages/emerald_package.png" alt="Avatar" width="43" height="33">
                                                    </div>
                                                </div>
                                            
                                                <div class="progress progress-bar-primary box-shadow-6">
                                                
                                                <div class="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="1" aria-valuemax="5"  :style="'width:'+(jade_record.left_count/5 * 100)+'%'" aria-describedby="example-caption-3"></div>
                                                </div>

                                            </div>

                                            <div class="col-6 border">
                                            <h6>Right Group <br> Emerald Packages</h6>
                                            <h6>{{jade_record.right_count}}/5</h6>
                                            
                                            <!-- <img src="/packages/emerald_package.png" style="max-width: 40px;" alt="">
                                            <img src="/packages/emerald_package.png" style="max-width: 40px;" alt=""> -->

                                                <div class="progress progress-bar-primary box-shadow-6">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="1" aria-valuemax="5" :style="'width:'+(jade_record.right_count/5 * 100)+'%'" aria-describedby="example-caption-3"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="text-center py-3">
                                            <button class="btn btn-primary">view more</button>
                                        </div>

                                        

                                    </div>

                                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">

                                                        <div class="stair-logo ">
                                                            <img class="package_icon" src="/stair_icons/phoenix-15.png" alt="">
                                                        </div>


                                                        
                                        <div class="row">
                                            <div class="col-6">
                                            <h6>Left Group <br> Emerald Packages</h6>
                                            <h6>1/5</h6>
                                            
                                                <div class="progress progress-bar-primary box-shadow-6">
                                                
                                                <div class="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="1" aria-valuemax="5"  style="width:10%" aria-describedby="example-caption-3"></div>
                                                </div>

                                            </div>

                                            <div class="col-6">
                                            <h6>Right Group <br> Emerald Packages</h6>
                                            <h6>1/5</h6>
                                                <div class="progress progress-bar-primary box-shadow-6">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="1" aria-valuemax="5" style="width:12%" aria-describedby="example-caption-3"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="text-center py-3">
                                            <button class="btn btn-primary">view more</button>
                                        </div> 


                                        ...
                                    </div>

                                    <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">

                                                        <div class="stair-logo ">
                                                            <img class="package_icon" src="/stair_icons/phoenix-21.png" alt="">
                                                        </div>


                                                        
                                        <div class="row">
                                            <div class="col-6">
                                            <h6>Left Group <br> Emerald Packages</h6>
                                            <h6>1/5</h6>
                                            
                                                <div class="progress progress-bar-primary box-shadow-6">
                                                
                                                <div class="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="1" aria-valuemax="5"  style="width:10%" aria-describedby="example-caption-3"></div>
                                                </div>

                                            </div>

                                            <div class="col-6">
                                            <h6>Right Group <br> Emerald Packages</h6>
                                            <h6>1/5</h6>
                                                <div class="progress progress-bar-primary box-shadow-6">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="1" aria-valuemax="5" style="width:12%" aria-describedby="example-caption-3"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="text-center py-3">
                                            <button class="btn btn-primary">view more</button>
                                        </div> 


                                        ...
                                    </div>
                                
                                    <div class="tab-pane fade" id="pills-zircon" role="tabpanel" aria-labelledby="pills-zircon-tab">

                                                            <div class="stair-logo ">
                                                            <img class="package_icon" src="/stair_icons/phoenix-27.png" alt="">
                                                        </div>


                                                        
                                        <div class="row">
                                            <div class="col-6">
                                            <h6>Left Group <br> Emerald Packages</h6>
                                            <h6>1/5</h6>
                                            
                                                <div class="progress progress-bar-primary box-shadow-6">
                                                
                                                <div class="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="1" aria-valuemax="5"  style="width:10%" aria-describedby="example-caption-3"></div>
                                                </div>

                                            </div>

                                            <div class="col-6">
                                            <h6>Right Group <br> Emerald Packages</h6>
                                            <h6>1/5</h6>
                                                <div class="progress progress-bar-primary box-shadow-6">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="1" aria-valuemax="5" style="width:12%" aria-describedby="example-caption-3"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="text-center py-3">
                                            <button class="btn btn-primary">view more</button>
                                        </div>
                                          ...
                                    </div>
                                    

                                    <div class="tab-pane fade" id="pills-director" role="tabpanel" aria-labelledby="pills-zircon-tab">

                                                            <div class="stair-logo ">
                                                            <img class="package_icon" src="/stair_icons/phoenix-37-1.png" alt="">
                                                        </div>


                                                        
                                        <div class="row">
                                            <div class="col-6">
                                            <h6>Left Group <br> Emerald Packages</h6>
                                            <h6>1/5</h6>
                                            
                                                <div class="progress progress-bar-primary box-shadow-6">
                                                
                                                <div class="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="1" aria-valuemax="5"  style="width:10%" aria-describedby="example-caption-3"></div>
                                                </div>

                                            </div>

                                            <div class="col-6">
                                            <h6>Right Group <br> Emerald Packages</h6>
                                            <h6>1/5</h6>
                                                <div class="progress progress-bar-primary box-shadow-6">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="1" aria-valuemax="5" style="width:12%" aria-describedby="example-caption-3"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="text-center py-3">
                                            <button class="btn btn-primary">view more</button>
                                        </div>
                                          ...
                                    </div>
                                    </div>
                                </div>
                               
                               
                               
                            </div> 


  </div>
  <!-- <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">...</div> -->
</div>
               

                
            </div>
        </div>
        
    </div>

</div>


</template>

<script>
import axios from 'axios'
export default {
   data(){
       return {
            direct_referrals: [],
            second_gen_indr_data: [],
            third_gen_indr_data: [],
            three_one_data: [],
            wallet_balance: '',

            direct_referral_sum: '',
            second_gen_indr_sum: '',
            third_gen_indr_sum: '',
            three_one_sum: '',

            left_generations: [],
            right_generations: [],
            total_left_points: '',
            total_right_points: '',
            current_points: null,

            loading: false,

            jade_record: []
       }
   },
    methods: {

        
        formatDigits(digits){
     
            return new Intl.NumberFormat('en-US',{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            } ).format(digits)
        },

        getTransactions(){
            axios({
                url: process.env.VUE_APP_URL + '/api/admin_wallet_balance',
                data:{
                    type: 'admin_type'
                },
                method: 'get',
                headers:{
                    'Authorization': 'Bearer '+localStorage.getItem('user_token')
                }
            })
            .then((response) => {

                console.log(response)

                this.transactions = response.data.user_wallet.data

                this.links = response.data.user_wallet.links

                this.wallet_balance = response.data.wallet_balance

                this.direct_referrals = response.data.direct_referrals
                this.second_gen_indr_data = response.data.second_gen_indr_data
                this.third_gen_indr_data = response.data.third_gen_indr_data
                this.three_one_data = response.data.three_one_data

                this.direct_referral_sum = response.data.direct_referral_sum
                this.second_gen_indr_sum = response.data.second_gen_indr_sum
                this.third_gen_indr_sum = response.data.third_gen_indr_sum
                this.three_one_sum = response.data.three_one_sum

                    if (response.data.genealogy_data) {

                        var genealogy_data = response.data.genealogy_data

                        this.left_generations = genealogy_data.left_generations

                        this.right_generations = genealogy_data.right_generations

                        this.total_left_points = genealogy_data.total_left_points

                        this.total_right_points = genealogy_data.total_right_points

                        this.current_points = genealogy_data.current_points

                        
                    }

                

                console.log(response)
            })
            .catch((response) => {

                console.log(response)
            })
        },

        checkJade(){

            axios({
                url: process.env.VUE_APP_URL+'/api/jade',
                method: 'post',
                headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' +localStorage.getItem('user_token')
                    },


            })
            .then((response)=>{

                // alert('hello');

                this.jade_record = response.data.record

                console.log(response)

            })
            .catch((response)=>{

                console.log(response)
            })

        }




        
    },

    mounted() {

        this.checkJade()
        this.getTransactions()
        
    },
}
</script>

<style scoped>
   .package_icon {
           
            height: 60px;
        }
</style>


